import gtm from '../../../../lib/gtm';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { GTM_EVENTS } from '../../../../constants';
import { withErrorSuspense } from 'utils/withErrorSuspense';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { CreateEducationForm } from 'components/education/CreateEducationForm';
import { CreateEducationCoverImageTable } from 'components/education/CreateEducationCoverImageTable';
import { useNavigate, useParams } from 'react-router';
import { useGetEducationCategories } from 'api/hooks/useGetEducationCategories';
import { EducationCategory } from 'types/education';
import { addEducationCategory } from 'api/addEducationCategory';
import { updateEducationCategory } from 'api/updateEducationCategory';
import LoadingScreen from 'components/LoadingScreen';
import { useSnackbar } from 'notistack';
import { createOpts } from 'snacks';
import { CreateEducationMaterialEpisodes } from 'components/education/CreateEducationMaterialEpisodes';
import { deleteEducationCategory } from 'api/deleteEducationCategory';
import { mutate } from 'swr';
import { SwrKeys } from 'swrKeys';
import { useForceUpdate } from 'hooks/useForceUpdate';

const CreateEducationMaterial: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const finalCategoryId = categoryId ? parseInt(categoryId) : null;
  const { data: categories } = useGetEducationCategories();
  const { enqueueSnackbar } = useSnackbar();
  const [coverImageSource, setCoverImageSource] = useState(null);
  const [categoryFormIsValid, setCategoryFormIsValid] = useState<boolean>(false);
  const coverImageSourceRef = useRef(null);
  const forceUpdate = useForceUpdate();
  const getInitialCategoryValue = () => {
    const categoryInEdit = categories.find((cat) => cat.id === finalCategoryId);
    return (
      categoryInEdit || {
        id: null,
        position: '' as any,
        title: '',
        description: '',
        coverImage: null,
        visible: false,
      }
    );
  };
  const [category, setCategory] = useState(getInitialCategoryValue());
  const categoryRef = useRef<EducationCategory>(getInitialCategoryValue());

  const setUpdatedCategoryValues = (updatedValues) => {
    categoryRef.current = updatedValues;
    setCategory(updatedValues);
  };
  const setUpdatedCategoryCoverImage = (coverImageValue) => {
    coverImageSourceRef.current = coverImageValue;
    setCoverImageSource(coverImageValue);
  };
  useEffect(() => {
    setUpdatedCategoryCoverImage({
      id: null,
      source: category?.coverImage,
      title: null,
      type: 'COVER',
    });
  }, [category]);

  useEffect(() => {
    gtm.push({ event: GTM_EVENTS.PAGE_VIEW, pageTitle: 'Create Education Material' });
  }, []);

  const handleCategoryEdit = async () => {
    try {
      setIsLoading(true);
      const response = await updateEducationCategory(category as any);

      enqueueSnackbar(
        t('education.apiMessages.educationCategoryUpdateSuccess'),
        createOpts('success') as any
      );
      setUpdatedCategoryCoverImage({
        ...coverImageSourceRef.current,
        coverImage: response.coverImage,
      });
      setUpdatedCategoryValues(response);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      enqueueSnackbar(
        t('education.apiMessages.educationCategoryUpdateError'),
        createOpts('error') as any
      );
    }
  };
  const handleCategoryCreation = async () => {
    try {
      setIsLoading(true);
      const response: EducationCategory = await addEducationCategory({
        title: category.title,
        description: category.description,
        position: category.position,
        visible: false,
        coverImage: category.coverImage as any,
      });

      enqueueSnackbar(
        t('education.apiMessages.educationCategoryCreationSuccess'),
        createOpts('success') as any
      );
      setUpdatedCategoryCoverImage({
        ...coverImageSourceRef.current,
        coverImage: response.coverImage,
      });
      setUpdatedCategoryValues(response);
      setIsLoading(false);
      navigate(`/dashboard/edit-education-material/${response.id}`);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      enqueueSnackbar(
        t('education.apiMessages.educationCategoryCreationError'),
        createOpts('error') as any
      );
    }
  };

  const onSaveEducationMaterial = async () => {
    if (!categoryFormIsValid) {
      enqueueSnackbar(
        t('education.apiMessages.educationCategoryFixFormIssues'),
        createOpts('error') as any
      );
      return;
    }
    if (!category.title || !category.description || !category.position) {
      enqueueSnackbar(
        t('education.apiMessages.educationCategoryMissingFieldsError'),
        createOpts('error') as any
      );
      return;
    }
    if (!category.coverImage) {
      enqueueSnackbar(
        t('education.apiMessages.educationCategoryMissingCoverImageError'),
        createOpts('error') as any
      );
      return;
    }
    await (finalCategoryId ? handleCategoryEdit() : handleCategoryCreation());
  };

  const onPreviewCategory = () => {
    navigate(`/dashboard/investor-education/${finalCategoryId}`);
  };

  const onPublishOrUnpublishCategory = async () => {
    if (!category.id) {
      return;
    }
    try {
      setIsLoading(true);
      await updateEducationCategory({ id: category.id, visible: !category.visible });
      enqueueSnackbar(
        category.visible
          ? t('education.apiMessages.educationCategoryUnPublishSuccess')
          : t('education.apiMessages.educationCategoryPublishSuccess'),
        createOpts('success') as any
      );
      category.visible = !category.visible;
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(
        category.visible
          ? t('education.apiMessages.educationCategoryUnPublishError')
          : t('education.apiMessages.educationCategoryPublishError'),
        createOpts('error') as any
      );
      setIsLoading(false);
    }
  };

  const onCoverImageUpload = (file) => {
    setUpdatedCategoryValues({ ...category, coverImage: file });
    setUpdatedCategoryCoverImage({ ...coverImageSourceRef.current, source: file });
    forceUpdate();
  };

  const onEducationCategoryFormValueChange = (values) => {
    category.title = values.title;
    category.description = values.description;
    category.position = values.position;
  };
  const onEducationCategoryFormValidityChange = (isValid: boolean) => {
    setCategoryFormIsValid(isValid);
  };

  const onDeleteCategory = async () => {
    try {
      setIsLoading(true);
      await deleteEducationCategory({ id: finalCategoryId });
      enqueueSnackbar(
        t('education.apiMessages.educationCategoryDeleteSuccess'),
        createOpts('success') as any
      );
      mutate([SwrKeys.useGetEducationCategories]);
      navigate(`/dashboard/education-workspace`);
    } catch (e) {
      enqueueSnackbar(
        t('education.apiMessages.educationCategoryDeleteError'),
        createOpts('error') as any
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box sx={{ position: 'relative' }}>
      {isLoading && (
        <Box sx={{ position: 'absolute', zIndex: 999, top: '0', bottom: 0, left: 0, right: 0 }}>
          <LoadingScreen />
        </Box>
      )}

      <Box>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: '20px' }}>
          {t('education.createMaterial.presentation')}
        </Typography>
        <Box
          sx={{
            mb: '16px',
            p: '16px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            borderRadius: '12px',
          }}
        >
          {!isLoading && (
            <>
              <CreateEducationForm
                title={category.title}
                description={category.description}
                position={category.position}
                onValidityChange={onEducationCategoryFormValidityChange}
                onChange={onEducationCategoryFormValueChange}
              />
              <CreateEducationCoverImageTable
                uploadedDocument={coverImageSource}
                onCoverImageUpload={onCoverImageUpload}
              />
            </>
          )}

          <Box>
            <Button
              sx={{ mt: 2, width: '100%' }}
              variant="contained"
              fullWidth={false}
              onClick={onSaveEducationMaterial.bind(this)}
            >
              {t('education.createMaterial.save')}
            </Button>
          </Box>
          {!finalCategoryId && (
            <Box
              sx={{
                color: '#856404',
                backgroundColor: '#fff3cd',
                borderColor: '#ffeeba',
                padding: '0.75rem 1.25rem',
                borderRadius: '0.25rem',
                mt: '16px',
              }}
            >
              <Typography>{t('education.createMaterial.categorySaveWarning')}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        {finalCategoryId && (
          <CreateEducationMaterialEpisodes
            categoryId={finalCategoryId as number}
            isLoading={(loadingValue) => setIsLoading(loadingValue)}
          />
        )}
      </Box>
      {categoryId && (
        <Button
          sx={{ mt: 2, width: '100%' }}
          variant="contained"
          fullWidth={false}
          onClick={onDeleteCategory.bind(this)}
        >
          {t('education.createMaterial.delete')}
        </Button>
      )}
      {categoryId && (
        <Button
          sx={{ mt: 2, width: '100%' }}
          variant="contained"
          fullWidth={false}
          onClick={onPublishOrUnpublishCategory.bind(this)}
        >
          {category.visible
            ? t('education.createMaterial.unpublish')
            : t('education.createMaterial.publish')}
        </Button>
      )}
      {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}> */}
      {categoryId && (
        <Button
          sx={{ mt: 2, width: '100%' }}
          variant="contained"
          fullWidth={false}
          onClick={onPreviewCategory.bind(this)}
        >
          {t('education.createMaterial.preview')}
        </Button>
      )}
    </Box>
  );
};

export default withErrorSuspense(CreateEducationMaterial);
