export enum SwrKeys {
  getUserOnboardList = 'getUserOnboardList',
  getCompanyById = 'getCompanyById',
  getUserCompanies = 'getUserCompanies',
  getUserOnboardById = 'getUserOnboardById',
  getUserCampaignsList = 'getUserCampaignsList',
  useGetInvestorList = 'useGetInvestorList',
  useGetIssuerList = 'useGetIssuerList',
  useGetUserById = 'useGetUserById',
  useGetUserOnboardFilesById = 'useGetUserOnboardFilesById',
  getUsers = 'getUsers',
  getAdminUserInvestorProfileCategory = 'getAdminUserInvestorProfileCategory',
  getCampaignPriorityList = 'getCampaignPriorityList',
  getCampaignById = 'getCampaignById',
  getCampaignInvestmentsByCampaignId = 'getCampaignInvestmentsByCampaignId',
  getUserInvestmentsByUserId = 'getUserInvestmentsByUserId',
  getCampaignStatusById = 'getCampaignStatusById',
  getSimpleUserByUserId = 'getSimpleUserByUserId',
  getUserCampaignsByUserId = 'getUserCampaignsByUserId',
  getCampaignFilesById = 'getCampaignFilesById',
  getCampaignMembersById = 'getCampaignMembersById',
  useGetInvestmentById = 'useGetInvestmentById',
  getInvestmentsList = 'getInvestmentsList',
  generate2fa = 'generate2fa',
  getNewsFeedList = 'getNewsFeedList',
  useGetNewsFeedById = 'useGetNewsFeedById',
  getInvestmentQuestions = 'getInvestmentQuestions',
  getInvestmentAnswers = 'getInvestmentAnswers',
  getFeedbackForm = 'getFeedbackForm',
  getLastUserKyc = 'getLastUserKyc',
  getUserKycFiles = 'getUserKycFiles',
  getInvestmentFiles = 'getInvestmentFiles',
  useGetCurrentUser = 'useGetCurrentUser',
  useGetUserInvestorProfileCategory = 'useGetUserInvestorProfileCategory',
  useGetEducationCategories = 'useGetEducationCategories',
  useGetEducationEpisodes = 'useGetEducationEpisodes',
  useGetEducationCategoryById = 'useGetEducationCategoryById',
}
