import { UserRole } from './types/user';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import LaunchIcon from '@mui/icons-material/Launch';
import FeedIcon from '@mui/icons-material/Feed';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

type Visibility = Array<UserRole | 'ANY'>;

interface SectionItem {
  title: string;
  path: string;
  icon: JSX.Element;
  visibility?: Visibility;
}
interface Section {
  title: string;
  items: SectionItem[];
  visibility: Visibility;
}

const sections: Section[] = [
  {
    title: 'menuItems.general',
    visibility: ['ANY'],
    items: [
      {
        title: 'menuItems.overview',
        path: '/dashboard',
        icon: <VisibilityIcon fontSize="small" />,
        visibility: ['ANY'],
      },
    ],
  },
  {
    title: 'menuItems.workspace',
    visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
    items: [
      {
        title: 'menuItems.issuers',
        path: '/dashboard/issuers-workspace',
        icon: <AccountBalanceIcon fontSize="small" />,
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      {
        title: 'menuItems.investors',
        path: '/dashboard/investors-workspace',
        icon: <AttachMoneyIcon fontSize="small" />,
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      {
        title: 'menuItems.campaigns',
        path: '/dashboard/campaigns-workspace',
        icon: <EmojiObjectsIcon fontSize="small" />,
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      {
        title: 'menuItems.investments',
        path: '/dashboard/investments-workspace',
        icon: <TrendingUpIcon fontSize="small" />,
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      {
        title: 'menuItems.education',
        path: '/dashboard/education-workspace',
        icon: <LocalLibraryIcon fontSize="small" />,
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      // {
      //   title: 'menuItems.newsFeed',
      //   path: '/dashboard/news-feed-workspace',
      //   icon: <FeedIcon fontSize="small" />,
      //   visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      // },
    ],
  },
  {
    title: 'menuItems.crowdfunding',
    visibility: ['ISSUER'],
    items: [
      {
        title: 'menuItems.companyProfile',
        path: '/dashboard/company-profile',
        icon: <BusinessIcon fontSize="small" />,
        visibility: ['ISSUER'],
      },
      {
        title: 'menuItems.startCampaign',
        path: '/dashboard/crowdfunding-campaign',
        icon: <LaunchIcon fontSize="small" />,
        visibility: ['ISSUER'],
      },
      // {
      //   title: 'menuItems.newsFeed',
      //   path: '/dashboard/news-feed',
      //   icon: <FeedIcon fontSize="small" />,
      //   visibility: ['ISSUER'],
      // },
    ],
  },
  {
    title: 'menuItems.investing',
    visibility: ['INVESTOR'],
    items: [
      {
        title: 'menuItems.myProfile',
        path: '/dashboard/investor-profile',
        icon: <PersonIcon fontSize="small" />,
        visibility: ['INVESTOR'],
      },
      {
        title: 'menuItems.myInvestments',
        path: '/dashboard/investor-investments',
        icon: <TrendingUpIcon fontSize="small" />,
        visibility: ['INVESTOR'],
      },
      {
        title: 'menuItems.education',
        path: '/dashboard/investor-education',
        icon: <LocalLibraryIcon fontSize="small" />,
        visibility: ['INVESTOR'],
      },
      // {
      //   title: 'menuItems.newsFeed',
      //   path: '/dashboard/news-feed',
      //   icon: <FeedIcon fontSize="small" />,
      //   visibility: ['INVESTOR'],
      // },
    ],
  },
  {
    title: 'menuItems.myAccount',
    visibility: ['ANY'],
    items: [
      {
        title: 'menuItems.settings',
        path: '/dashboard/account',
        icon: <SettingsIcon fontSize="small" />,
        visibility: ['ANY'],
      },
    ],
  },
];

export const getSections = (userRole: UserRole, hasEducation: boolean): Section[] => {
  const filterByRole = (section: Section | SectionItem) =>
    section.visibility.includes(userRole) || section.visibility.includes('ANY');
  const filteredSections = sections.map((section) => {
    return {
      ...section,
      items: section.items.filter(filterByRole).filter((item) => {
        if (item.path.includes('dashboard/investor-education')) {
          return hasEducation || ['ADMIN', 'ACCOUNT_MANAGER'].includes(userRole);
        }
        return true;
      }),
    };
  });
  return filteredSections.filter(filterByRole);
};
