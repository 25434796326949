import { useState } from 'react';
import { Box, Dialog, DialogProps, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ResponsiveVideoPlayer from 'components/generic/ResponsiveVideoPlayer';

type Props = DialogProps & {
  title: string;
  videoUrl: string;
};

export const InvestorEducationViewVideoModal = (props: Props): JSX.Element => {
  const { title, videoUrl } = props;
  const [open, setOpen] = useState(true);
  const onCancel = () => {
    setOpen(false);
  };
  const isXs = useMediaQuery('(max-width:600px)');
  return (
    <Dialog open={open} onClose={onCancel} maxWidth={false}>
      <Box
        sx={
          isXs
            ? { width: '100%', height: '100%' }
            : { minWidth: '500px', maxWidth: '100%', maxHeight: '100%' }
        }
      >
        {/* Header */}
        <Box
          sx={{
            height: '67px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px',
            borderBottom: '1px solid #d3d3d3',
          }}
        >
          <Typography variant="h6" sx={{ flex: '1 1 calc(100% - 50px)' }}>
            {title}
          </Typography>
          <Box
            sx={{
              width: '50px',
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CloseIcon
              onClick={onCancel}
              sx={{ cursor: 'pointer', width: '30px', height: '30px' }}
            />
          </Box>
        </Box>
        {/* Content */}
        <Box sx={{ height: 'calc(100% - 67px)', width: '100%', padding: '8px 16px' }}>
          {/* <ResponsiveVideoPlayer url={videoUrl} controls></ResponsiveVideoPlayer> */}
          <video
            controls
            preload="metadata"
            style={{ width: '100%', height: ' 100%' }}
            src={videoUrl + '#t=0.001'}
            controlsList="nodownload"
          ></video>
        </Box>
      </Box>
    </Dialog>
  );
};
