/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import i18n from 'i18next';
import { SiteName } from '../constants';
import { utcToZonedTime, format } from 'date-fns-tz';
import stc from 'string-to-color';
import Resizer from 'react-image-file-resizer';
import { CampaignCurrency } from 'types/campaign';
import { countriesOptions } from 'types/countries-options';

// eslint-disable-next-line
export const PHONE_NUMBER_VALIDATION = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
// eslint-disable-next-line
export const YOUTUBE_LINK_VALIDATION = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/g;
export const NAME_VALIDATION = /^[^<>{}\[\]\/\\(\)]{1,255}$/;
export const NAME_VALIDATION_EXTRA_DENY_DOT = /^[^<>{}\[\]\/\\(\)\.]{1,255}$/;
// eslint-disable-next-line
export const WEBSITE_VALIDATION = /^(https?|ftp|file):\/\/[-a-zA-Z0-9+&@#\/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#\/%=~_|]/;

export const formatPageTitle = (title: string): string => `${title} | ${SiteName}`;

export const mergeTwoObjectArraysByProperty = (arr1, arr2, prop: string) => {
  return [
    ...arr1
      .concat(arr2)
      .reduce((m, o) => m.set(o[prop], Object.assign(m.get(o[prop]) || {}, o)), new Map())
      .values(),
  ];
};

export const mapCountryCodeToLabel = (countryCode) => {
  return !countryCode
    ? ''
    : i18n.t(countriesOptions.find((item) => item.key === countryCode)?.value || '');
};

export const isIosSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
};

export const dataURIToBlob = (dataURI: string, mimeString?: string): Blob => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  if (!mimeString) {
    mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  }
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const blobToFile = (blob: Blob, fileName: string, type): File => {
  const b = blob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  // b['lastModifiedDate'] = new Date();
  // b['name'] = fileName;
  // b['filename'] = fileName;

  //Cast to a File() type
  return new File([blob], fileName, { type: type, lastModified: new Date().getTime() });
};

export const generateSeededHexColor = (seed): string => stc(seed);

export const getLanguage = (): string => i18n.language || window.localStorage.i18nextLng;
export const getLocale = (language = getLanguage()): string => {
  switch (language) {
    case 'ro':
      return 'ro-RO';
    case 'en':
      return 'en-GB';
  }
};

export const dateTimeFormat = (date: number | Date, options?: unknown, locale?: string): string => {
  if (!locale) {
    locale = getLocale();
  }

  if (!options) {
    options = { dateStyle: 'full', timeStyle: 'long' };
  }

  return new Intl.DateTimeFormat(locale).format(date);
};

export const extractLinksFromString = (input: string) => {
  const expression = /href="(.*?)"/g;
  const matches = input.match(expression);
  const results = [];
  (matches || []).forEach((match) => {
    const result = {};
    const finalMatch = match.match(/href=\"(.*?)\"/i)[1];
    result['link'] = finalMatch;
    results.push(result);
  });

  return results;
};

export const daysUntil = (date: number | Date): string => {
  return Math.ceil(
    Math.abs(new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  ).toString();
};

export const numberFormat = (
  number: number,
  campaignCurrency: CampaignCurrency,
  options?: unknown
): string => {
  switch (campaignCurrency) {
    case 'RON':
      options = {
        style: 'currency',
        currency: 'RON',
        minimumFractionDigits: 0,
      };
      break;
    case 'EUR':
      options = {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
      };
      break;
    default:
      options = {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
      };
  }

  return new Intl.NumberFormat(undefined, options).format(number);
};

export const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const convertUtcDateToClientTimeZone = (date: string | number | Date) => {
  const zonedDate = utcToZonedTime(date, clientTimeZone);
  const pattern = "d.M.yyyy HH:mm:ss 'GMT' XXX (z)";
  const output = format(zonedDate, pattern, { timeZone: clientTimeZone });

  return output;
};

export const isImageFile = (file: File): boolean => {
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const fileType = file['type'];
  if (validImageTypes.includes(fileType)) {
    return true;
  }
  return false;
};

export const resizeFile = (file: File, width = 3840, height = 2160): Promise<string> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      100,
      0,
      (uri: string) => {
        resolve(uri);
      },
      'base64'
    );
  });
};

export const uuidv4 = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};
