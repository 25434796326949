import { Box, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
  handleClick: () => void;
  stylingProps?: Partial<SxProps<Theme>>;
  height?: number;
  width?: number;
  borderRadius?: number;
  float?: string;
}

export const InfoButton = (props: Props): JSX.Element => {
  const { handleClick, stylingProps = {}, width, height, borderRadius, float } = props;
  return (
    <Box
      onClick={handleClick}
      sx={{
        height: height ? height + 'px' : '36px',
        width: width ? width + 'px' : '36px',
        border: '1px solid #3769FF',
        borderRadius: borderRadius ? borderRadius + 'px' : '4px',
        cursor: 'pointer',
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'primary.main',
        float: float ? 'right' : 'none',
        marginLeft: '5px',
        '&:hover': {
          backgroundColor: 'primary.main',
          cursor: 'pointer',
          color: 'primary.contrastText',
        },
        ...stylingProps,
      }}
    >
      <Typography variant="h6">i</Typography>
    </Box>
  );
};
