import { EdexClientJava } from 'lib/axios';
import { SWRHook } from '../../hooks/hook.types';
import useSWR from 'swr';
import { EducationEpisode } from 'types/education';
import { SwrKeys } from 'swrKeys';

export const getEducationEpisodes = async (
  _key,
  categoryId: number
): Promise<EducationEpisode[]> => {
  try {
    if (!categoryId) {
      return [];
    }
    const client = await EdexClientJava.getClient();
    const response = await client.educationControllerEducationEpisodeList({ id: categoryId });
    return response.data.sort(({ position: a }, { position: b }) => a - b);
  } catch (err) {
    throw new Error(err);
  }
};

export function useGetEducationEpisodes(id: number): SWRHook<EducationEpisode[]> {
  const { data, error, mutate } = useSWR(
    [SwrKeys.useGetEducationEpisodes, id],
    getEducationEpisodes
  );
  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
}
