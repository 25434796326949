import axios from 'axios';

export const downloadEducationSupportFile = async (url): Promise<any> => {
  try {
    const resp = await axios({
      method: 'get',
      url: url,
      responseType: 'arraybuffer',
    });
    return resp;
  } catch (err) {
    throw new Error(err);
  }
};
