import { EdexClientJava } from '../lib/axios';

export const getEducationMaterialVideoUploadUrl = async ({
  id,
  episode_id,
}: {
  id: number;
  episode_id: number;
}): Promise<{ url: string }> => {
  try {
    const client = await EdexClientJava.getClient();
    const response = await client.educationControllerGetEpisodeVideoUploadUrl({ id, episode_id });
    const resp = response.data as { url: string };
    return resp;
  } catch (err) {
    throw new Error(err);
  }
};
