import { EdexClientJava } from '../lib/axios';

export const deleteEducationCategory = async ({ id }: { id: number }): Promise<void> => {
  try {
    const client = await EdexClientJava.getClient();
    await client.educationControllerDeleteEducationCategory({
      id,
    });
    return;
  } catch (err) {
    throw new Error(err);
  }
};
