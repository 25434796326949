import { CountryEnum } from './countries.enum';

export interface CountriesOptions {
  key: CountryEnum;
  value: string;
}

export const countriesOptions: CountriesOptions[] = [
  { key: 'AT', value: 'campaign.countries.AT' },
  { key: 'BE', value: 'campaign.countries.BE' },
  { key: 'BG', value: 'campaign.countries.BG' },
  { key: 'HR', value: 'campaign.countries.HR' },
  { key: 'CY', value: 'campaign.countries.CY' },
  { key: 'CZ', value: 'campaign.countries.CZ' },
  { key: 'DK', value: 'campaign.countries.DK' },
  { key: 'EE', value: 'campaign.countries.EE' },
  { key: 'FI', value: 'campaign.countries.FI' },
  { key: 'FR', value: 'campaign.countries.FR' },
  { key: 'DE', value: 'campaign.countries.DE' },
  { key: 'GR', value: 'campaign.countries.GR' },
  { key: 'HU', value: 'campaign.countries.HU' },
  { key: 'IE', value: 'campaign.countries.IE' },
  { key: 'IS', value: 'campaign.countries.IS' },
  { key: 'IT', value: 'campaign.countries.IT' },
  { key: 'IL', value: 'campaign.countries.IL' },
  { key: 'LV', value: 'campaign.countries.LV' },
  { key: 'LI', value: 'campaign.countries.LI' },
  { key: 'LT', value: 'campaign.countries.LT' },
  { key: 'LU', value: 'campaign.countries.LU' },
  { key: 'MT', value: 'campaign.countries.MT' },
  { key: 'NL', value: 'campaign.countries.NL' },
  { key: 'NO', value: 'campaign.countries.NO' },
  { key: 'PL', value: 'campaign.countries.PL' },
  { key: 'PT', value: 'campaign.countries.PT' },
  { key: 'RO', value: 'campaign.countries.RO' },
  { key: 'SK', value: 'campaign.countries.SK' },
  { key: 'SI', value: 'campaign.countries.SI' },
  { key: 'ES', value: 'campaign.countries.ES' },
  { key: 'SE', value: 'campaign.countries.SE' },
  { key: 'CH', value: 'campaign.countries.CH' },
  { key: 'UK', value: 'campaign.countries.UK' },
];
