import { EdexClientJava } from 'lib/axios';
import { SWRHook } from '../../hooks/hook.types';
import useSWR from 'swr';
import { EducationCategory, EducationEpisode } from 'types/education';
import { SwrKeys } from 'swrKeys';

export const getEducationalCategory = async (
  _key,
  categoryId: number
): Promise<EducationCategory> => {
  try {
    if (!categoryId) {
      return {};
    }
    const client = await EdexClientJava.getClient();
    const response = await client.educationControllerEducationCategoryList();
    return response.data.find((cat) => cat.id === categoryId);
  } catch (err) {
    throw new Error(err);
  }
};

export function useGetEducationalCategory(id: number): SWRHook<EducationCategory> {
  const { data, error, mutate } = useSWR(
    [SwrKeys.useGetEducationCategoryById, id],
    getEducationalCategory
  );
  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
}
