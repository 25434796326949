import { EdexClientJava } from '../lib/axios';
import { EducationCategory } from 'types/education';

export const updateEducationCategory = async ({
  id,
  title,
  position,
  description,
  visible,
  coverImage,
}: {
  id: number;
  title?: string;
  position?: number;
  description?: string;
  visible?: boolean;
  coverImage?: Blob;
}): Promise<EducationCategory> => {
  try {
    const client = await EdexClientJava.getClient();
    const formDataPayload = new FormData();
    if (typeof coverImage !== 'string') {
      formDataPayload.append('coverImage', coverImage);
    }

    const response = await client.educationControllerUpdateEducationCategory(
      {
        id,
        title,
        position,
        description,
        visible,
      },
      formDataPayload,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    const member = response.data as EducationCategory;
    return member;
  } catch (err) {
    throw new Error(err);
  }
};
