import { FC } from 'react';
import { useAuth } from 'hooks';
import { InvestorProfileReminderComplete } from './InvestorProfileReminderComplete';

export const InvestorProfileReminderCompleteWrapper: FC = () => {
  const { user } = useAuth();
  if (!user) {
    return <></>;
  }
  return <InvestorProfileReminderComplete />;
};
