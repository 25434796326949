import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { EdexClientJava } from '../lib/axios';
import type { RegisteredUserRole, UserOutputSingleDto } from '../types/user';
import { Routes } from '../constants';
import { RegisterSuccessSnack } from '../snacks';
import { EmailVerificationStatus, PasswordResetStatus, PasswordUpdateStatus } from '../types/auth';
import KeycloakUserService from 'utils/KeycloakUserService';
import i18n from 'i18n/i18n';
interface RegisterRequest {
  firstName: string;
  lastName: string;
  phone: string;
  role: RegisteredUserRole;
}

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isSecondFactorActive: boolean;
  user: UserOutputSingleDto | null;
  emailVerificationStatus: EmailVerificationStatus | null;
  passwordResetStatus: PasswordResetStatus | null;
  passwordUpdateStatus: PasswordUpdateStatus | null;
}

export interface AuthContextValue extends State {
  platform: 'JWT';
  logout: (locale: string) => Promise<void>;
  completeRegistration: (params: RegisterRequest) => Promise<void>;
  setCurrentUser: (user) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: UserOutputSingleDto | null;
  };
};
type LogoutAction = {
  type: 'LOGOUT';
};
type SetCurrentUserAction = {
  type: 'SET_CURRENT_USER';
  payload: {
    user: UserOutputSingleDto;
  };
};

type CompleteRegistrationAction = {
  type: 'COMPLETE_REGISTRATION';
  payload: {
    user: UserOutputSingleDto;
  };
};

type Action = InitializeAction | CompleteRegistrationAction | LogoutAction | SetCurrentUserAction;

const initialState: State = {
  isAuthenticated: false,
  isSecondFactorActive: false,
  isInitialized: false,
  user: null,
  emailVerificationStatus: null,
  passwordResetStatus: null,
  passwordUpdateStatus: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isSecondFactorActive: true,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    isSecondFactorActive: false,
    user: null,
  }),
  COMPLETE_REGISTRATION: (state: State, action: CompleteRegistrationAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SET_CURRENT_USER: (state: State, action: SetCurrentUserAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  logout: (locale: string) => Promise.resolve(),
  completeRegistration: () => Promise.resolve(),
  setCurrentUser: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        if (!KeycloakUserService.userNeedsRegistrationCompletion()) {
          const user = KeycloakUserService.getLoggedInUser();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const logout = async (locale: string): Promise<void> => {
    enqueueSnackbar(i18n.t('toastMessages.loggedOut'), {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      variant: 'info',
    });
    dispatch({ type: 'LOGOUT' });
    KeycloakUserService.doLogout().then(() => KeycloakUserService.doLogin({ locale }));
  };

  const setCurrentUser = async (user): Promise<void> => {
    dispatch({ type: 'SET_CURRENT_USER', payload: { user } });
  };
  // const loadLoggedInUserWithRetry = async (maxRetry = 3, retryDelay = 1000, retryCount = 1) => {
  //   if (retryCount === maxRetry) {
  //     throw new Error('Failed loading user after multiple attempts');
  //   }
  //   try {
  //     return await KeycloakUserService.loadLoggedInUser(false);
  //   } catch (err) {
  //     setTimeout(() => {
  //       loadLoggedInUserWithRetry(maxRetry, retryDelay, retryCount++);
  //     }, retryDelay);

  //   }
  // };
  const completeRegistration = async (payload: RegisterRequest): Promise<void> => {
    const { firstName, lastName, phone, role } = payload;
    const client = await EdexClientJava.getClient();
    try {
      const response = await client.userControllerRegister(undefined, {
        firstName,
        lastName,
        phone,
        role,
      });
      KeycloakUserService.setUserRegistrationSubmittedSuccesfully(true);
      if (response.status === 200) {
        enqueueSnackbar(i18n.t(RegisterSuccessSnack.message), {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
          variant: 'info',
        });
        dispatch({ type: 'COMPLETE_REGISTRATION', payload: { user: response.data } });
        await KeycloakUserService.loadLoggedInUser(false);

        KeycloakUserService.updateToken(
          async () => {},
          (err) => {
            KeycloakUserService.doLogin();
          }
        );

        navigate(Routes.Dashboard);
      }
    } catch (err) {
      const message = err.response?.data?.message || '';
      const errorsMessage = Array.isArray(message) ? [...message].join() : message;
      enqueueSnackbar(errorsMessage, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      });
      // throw new Error(errorsMessage);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        logout,
        completeRegistration,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
