import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useAuth } from 'hooks';
import { FC, useState } from 'react';
import { PRE_DEPLOYMENT_STAGING_TO_PRODUCTION_OVERWRITE } from 'utils/getApiUrlByEnv';

export const StagingAllowedUsersValidation: FC<any> = (props: any): JSX.Element => {
  const { user, logout } = useAuth();
  const [showWarning, setShowWarning] = useState(true);
  const ALLOWED_USERS_DURING_STAGING_OVERWRITE = [
    'sd_elena@yahoo.com',
    'bogdan.almasi@gmail.com',
    'elena@weronin.com',
    'stefan.ivan+investor33@wizhub.io',
    'mihaela@weronin.com',
    'moisevlad20+investortest@gmail.com',
    'ovidiu.ghiman@gmail.com',
  ];

  // We make sure we only display something if the build process environment was set to staging.
  // To prevent accidental display of messages when deploying to production if
  // the PRE_DEPLOYMENT_STAGING_TO_PRODUCTION_OVERWRITE variable is forgotten on TRUE value while deploying to prod.
  if (process?.env?.REACT_APP_ENV !== 'staging') {
    return <></>;
  }
  // If PRE_DEPLOYMENT_STAGING_TO_PRODUCTION_OVERWRITE is false, it means we are not in the pre-release process
  // and we don't display any warning to the user.
  if (!PRE_DEPLOYMENT_STAGING_TO_PRODUCTION_OVERWRITE) {
    return <></>;
  }
  // If user is not logged in we display the UI as always
  if (!user) {
    return <></>;
  }
  // If the user is logged in, we show a small reminder to him that staging is currently pointing to production
  if (ALLOWED_USERS_DURING_STAGING_OVERWRITE.includes(user.email)) {
    if (showWarning) {
      return (
        <Box
          sx={{
            color: '#721c24',
            backgroundColor: '#f8d7da',
            borderColor: '#f5c6cb',
            p: '8px',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999999,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '250px',
          }}
        >
          <p style={{ fontWeight: 'bold', margin: 0, fontSize: '12px' }}>
            YOU ARE CURRENTLY CONNECTED TO PRODUCTION DATA.
          </p>
          <p style={{ fontWeight: 'bold', margin: 0, fontSize: '12px' }}>
            DO NOT MAKE ANY UNINTENDED CHANGES TO PRODUCTION
          </p>
          <Button
            color="primary"
            sx={{ mb: 0 }}
            variant="text"
            onClick={() => setShowWarning(false)}
          >
            Close Warning
          </Button>
        </Box>
      );
    }
    return <></>;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white',
        zIndex: 9999999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p style={{ fontWeight: 'bold' }}>Staging is currently pointing to production data.</p>
      <p style={{ fontWeight: 'bold' }}>
        Only a limited subset of users is allowed to access staging at the current time.
      </p>
      <p style={{ fontWeight: 'bold' }}>For access request please contact Elena</p>
      <Button color="primary" sx={{ mb: 3 }} variant="text" onClick={() => logout('en')}>
        Logout
      </Button>
    </Box>
  );
};
