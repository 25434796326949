import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
export interface CreateEducationFileEntryProps {
  educationDocument: {
    source: Blob | string;
    type: 'VIDEO' | 'SLIDES' | 'COVER';
  };
  withDiscard?: boolean;
  handleUpload?: () => void;
  handleDiscard?: () => void;
}

export const CreateEducationFileEntry: FC<CreateEducationFileEntryProps> = (
  props: CreateEducationFileEntryProps
): JSX.Element => {
  const { educationDocument, handleUpload, handleDiscard, withDiscard = true } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const isDocumentUploaded = useMemo(() => {
    return Boolean(educationDocument?.source);
  }, [educationDocument?.source]);

  const handleFileUpload = () => {
    if (handleUpload) {
      handleUpload();
    }
  };
  const viewServerImage = () => {
    window.open(educationDocument.source as string, '_blank');
  };
  const viewLocalImage = () => {
    const file = educationDocument.source as File;
    const fileUrl = URL.createObjectURL(file);
    window.open(fileUrl, '_blank');
  };
  const handleFileView = () => {
    if (typeof educationDocument.source === 'string') {
      viewServerImage();
    } else if (educationDocument.source instanceof Blob) {
      viewLocalImage();
    }
  };
  const handleFileDiscard = () => {
    if (handleDiscard) {
      handleDiscard();
    }
  };

  const UploadButton = useMemo(() => {
    const isButtonDisabled = !withDiscard ? false : isDocumentUploaded;
    return mobileDevice ? (
      <UploadIcon
        sx={{
          color: isButtonDisabled ? '#E3E5E6' : '#3769ff',
          pointerEvents: isButtonDisabled ? 'none' : 'auto',
        }}
        onClick={handleFileUpload.bind(this)}
      />
    ) : (
      <Button
        variant="outlined"
        size="medium"
        color={isButtonDisabled ? 'secondary' : 'primary'}
        onClick={handleFileUpload.bind(this)}
        disabled={Boolean(isButtonDisabled)}
        sx={{
          border: 0,
          width: '100%',
          backgroundColor: '#D7E1FF',
          '&:hover': { border: 0 },
        }}
      >
        {t('generic.table.upload')}
      </Button>
    );
  }, [isDocumentUploaded, mobileDevice, withDiscard]);

  const ViewButton = useMemo(() => {
    return mobileDevice ? (
      <VisibilityIcon
        sx={{
          color: !isDocumentUploaded ? '#E3E5E6' : '#3769ff',
          pointerEvents: !isDocumentUploaded ? 'none' : 'auto',
        }}
        onClick={() => handleFileView()}
      />
    ) : (
      <Button
        variant="outlined"
        size="medium"
        color={isDocumentUploaded ? 'secondary' : 'primary'}
        onClick={() => handleFileView()}
        disabled={!Boolean(isDocumentUploaded)}
        sx={{
          border: 0,
          width: '100%',
          backgroundColor: '#D7E1FF',
          '&:hover': { border: 0 },
        }}
      >
        {t('generic.table.view')}
      </Button>
    );
  }, [isDocumentUploaded, mobileDevice, educationDocument]);
  const DiscardButton = useMemo(() => {
    if (!withDiscard) {
      return <></>;
    }
    return mobileDevice ? (
      <ClearIcon
        sx={{
          color: !isDocumentUploaded ? '#E3E5E6' : '#3769ff',
          pointerEvents: !isDocumentUploaded ? 'none' : 'auto',
        }}
        onClick={() => handleFileDiscard()}
      />
    ) : (
      <Button
        variant="outlined"
        size="medium"
        color={isDocumentUploaded ? 'secondary' : 'primary'}
        onClick={() => handleFileDiscard()}
        disabled={!Boolean(isDocumentUploaded)}
        sx={{
          border: 0,
          width: '100%',
          backgroundColor: '#D7E1FF',
          '&:hover': { border: 0 },
        }}
      >
        {t('generic.table.discard')}
      </Button>
    );
  }, [isDocumentUploaded, mobileDevice]);

  return (
    <Box sx={{ display: 'flex', m: '16px 0' }}>
      <Typography sx={{ flex: 1 }}>
        {educationDocument.type === 'VIDEO'
          ? t('education.createMaterial.videoSupport')
          : educationDocument.type === 'SLIDES'
          ? t('education.createMaterial.slidesSupport')
          : t('education.createMaterial.coverPhoto')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          '& > button': {
            ml: '8px',
          },
        }}
      >
        {UploadButton}
        {DiscardButton}
        {ViewButton}
      </Box>
    </Box>
  );
};
