import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { inputColors } from 'theme';
import { Typography } from '@mui/material';

interface RoninPhoneInputProps {
  name: string;
  value: string;
  error?: boolean;
  errorText?: string;
  label?: string;
  disabled?: boolean;
  setFieldValue: (prop, value: string) => void;
  handleBlur: (event) => void;
}

export const RoninPhoneInput = (props: RoninPhoneInputProps) => {
  const { name, value, label, error, disabled, errorText, setFieldValue, handleBlur } = props;
  const { t } = useTranslation();
  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {label && (
        <label
          style={{
            color: error
              ? inputColors.errorColor
              : !disabled && value
              ? inputColors.primaryColor
              : disabled && value
              ? inputColors.primaryColor
              : inputColors.secondaryColor,
            fontSize: '12px',
            letterSpacing: '-0.22px',
            fontFamily: 'Neue Haas Grotesk Display Pro, sans-serif',
            textAlign: 'left',
            transition: 'all 0.5s ease',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(133%-24px)',
            paddingTop: '8px',
            display: 'inline-block',
          }}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      {/* defaultErrorMessage={errorText} */}
      <PhoneInput
        enableSearch={true}
        containerClass="ronin-phone-input-container"
        inputClass="ronin-phone-input"
        dropdownClass="ronin-phone-dropdown-container"
        specialLabel=""
        country={'ro'}
        disabled={disabled}
        onChange={(value) => {
          setFieldValue(name, `+${value}`);
        }}
        isValid={() => {
          return !error;
        }}
        inputProps={{
          id: name,
          name: name,
          type: 'phone',
          placeholder: t('register.labels.phoneNumber'),
          onBlur: handleBlur,
          border: disabled ? '1px solid rgb(162, 170, 173)' : undefined,
        }}
        searchPlaceholder={t('register.labels.phoneNumberSearch')}
        value={value}
      />
      <Typography
        sx={{
          fontSize: '11px',
          marginTop: '5px',
          color: inputColors.errorColor,
        }}
      >
        {error ? errorText : null}
      </Typography>
    </Box>
  );
};
