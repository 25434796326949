import { EdexClientJava } from '../lib/axios';
import { EducationEpisode } from 'types/education';

export const updateEducationEpisode = async ({
  id,
  episode_id,
  title,
  position,
  videoFile,
  supportMaterial,
}: {
  id: number;
  episode_id: number;
  title?: string;
  position?: number;
  videoFile?: Blob;
  supportMaterial?: Blob;
}): Promise<EducationEpisode> => {
  try {
    const client = await EdexClientJava.getClient();
    const formDataPayload = new FormData();
    if (videoFile === null || videoFile) {
      formDataPayload.append('videoFile', videoFile);
    }
    if (supportMaterial === null || supportMaterial) {
      formDataPayload.append('supportMaterial', supportMaterial);
    }

    const response = await client.educationControllerUpdateEducationEpisode(
      { id, episode_id, title, position },
      formDataPayload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    const member = response.data as EducationEpisode;
    return member;
  } catch (err) {
    throw new Error(err);
  }
};
