import { EdexClientJava } from '../lib/axios';

export const deleteEducationEpisodeVideo = async ({
  id,
  episode_id,
}: {
  id: number;
  episode_id: number;
}): Promise<void> => {
  try {
    const client = await EdexClientJava.getClient();
    await client.educationControllerDeleteEducationEpisodeVideo({
      id,
      episode_id,
    });
    return;
  } catch (err) {
    throw new Error(err);
  }
};
