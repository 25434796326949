import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { CreateEducationDocumentsTable } from 'components/education/CreateEducationDocumentsTable';
import { useGetEducationEpisodes } from 'api/hooks/useGetEducationalEpisodes';
import { useSnackbar } from 'notistack';
import { createOpts } from 'snacks';
import { updateEducationEpisode } from 'api/updateEducationEpisode';
import { addEducationEpisode } from 'api/addEducationEpisode';
import { deleteEducationEpisode } from 'api/deleteEducationEpisode';
import { deleteEducationEpisodeDocument } from 'api/deleteEducationEpisodeSupportMaterial';
import { deleteEducationEpisodeVideo } from 'api/deleteEducationEpisodeVideo';
import { getEducationMaterialVideoUploadUrl } from 'api/getEducationMaterialVideoUploadUrl';
import axios from 'axios';
import { EducationEpisode } from 'types/education';
import { confirmEducationMaterialVideoUpload } from 'api/confirmEducationMaterialVideoUpload';

interface CreateEducationMaterialEpisodesProps {
  categoryId?: number;
  isLoading: (loading: boolean) => void;
}

export const CreateEducationMaterialEpisodes: FC<CreateEducationMaterialEpisodesProps> = (
  props: CreateEducationMaterialEpisodesProps
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { categoryId, isLoading } = props;
  const { data: categoryEpisodes } = useGetEducationEpisodes(categoryId);
  const episodesTableCategoriesRef = useRef<
    {
      id: number;
      title: string;
      position: number;
      isValid: boolean;
      tableEntries: {
        source: string | Blob;
        type: 'VIDEO' | 'SLIDES';
      }[];
    }[]
  >();
  const [episodesTableCategories, setEpisodesTableCategories] = useState<
    {
      id: number;
      title: string;
      position: number;
      isValid: boolean;
      tableEntries: {
        source: string | Blob;
        type: 'VIDEO' | 'SLIDES';
      }[];
    }[]
  >([]);

  const defaultTableEntry = {
    id: null,
    title: '',
    position: '' as any,
    isValid: false,
    tableEntries: [
      { source: undefined, type: 'VIDEO' as any },
      { source: undefined, type: 'SLIDES' as any },
    ],
  };
  /**
   * Episode model:
   *
   * episodeId
   * title
   * position
   * slidesSupport
   * videoSupport
   *
   */
  useEffect(() => {
    if (episodesTableCategories.length === 0) {
      const tableRowsToSet = categoryEpisodes.length
        ? categoryEpisodes.map((categoryEpisode) => {
            return {
              id: categoryEpisode.id,
              title: categoryEpisode.title,
              position: categoryEpisode.position,
              tableEntries: [
                {
                  id: categoryEpisode.id,
                  source: categoryEpisode.videoFile,
                  type: 'VIDEO',
                },
                {
                  id: categoryEpisode.id,
                  source: categoryEpisode.supportMaterial,
                  type: 'SLIDES',
                },
              ],
            };
          })
        : [defaultTableEntry];
      setUpdatedEpisodesTableCategoriesValues(tableRowsToSet);
    }
  }, [categoryEpisodes]);

  const onAddRow = () => {
    setUpdatedEpisodesTableCategoriesValues([
      ...episodesTableCategoriesRef.current,
      defaultTableEntry,
    ]);
    // setEpisodesTableCategories([...episodesTableCategories, defaultTableEntry]);
  };
  const setUpdatedEpisodesTableCategoriesValues = (updatedValues) => {
    episodesTableCategoriesRef.current = updatedValues;
    setEpisodesTableCategories(updatedValues);
  };
  const onEpisodeDocumentUpload = async (
    documentIndex: number,
    fileTypeIndex: number,
    file: Blob,
    docType: 'VIDEO' | 'SLIDES'
  ) => {
    try {
      // Handling the scenario where the episode is only saved locally.
      const educationEpisodeId = episodesTableCategoriesRef.current[documentIndex].id;
      if (!educationEpisodeId) {
        const updatedEpisodesTableCategories = episodesTableCategoriesRef.current.map(
          (documentEntry, index) => {
            if (index === documentIndex) {
              documentEntry.tableEntries[fileTypeIndex].source = file;
            }
            return documentEntry;
          }
        );
        setUpdatedEpisodesTableCategoriesValues(updatedEpisodesTableCategories);
        return;
      }
      // Handling the scenario where the episode is server side provided
      isLoading(true);
      const payload = {
        id: categoryId,
        episode_id: educationEpisodeId,
        title: episodesTableCategoriesRef.current[documentIndex].title,
        position: episodesTableCategoriesRef.current[documentIndex].position,
      } as any;
      let resp: EducationEpisode = null;
      if (docType === 'VIDEO') {
        payload.videoFile = file;
        const { url } = await getEducationMaterialVideoUploadUrl({
          id: categoryId,
          episode_id: educationEpisodeId,
        });
        const uploadResp = await axios({
          method: 'put',
          url: url,
          data: file,
          headers: {
            'Content-Type': file.type,
          },
        });
        resp = await confirmEducationMaterialVideoUpload({
          id: categoryId,
          episode_id: educationEpisodeId,
        });
      }
      if (docType === 'SLIDES') {
        payload.supportMaterial = file;
        resp = await updateEducationEpisode(payload);
      }

      const updatedEpisodesTableCategories = episodesTableCategoriesRef.current.map(
        (documentEntry, index) => {
          if (index === documentIndex) {
            documentEntry.tableEntries[fileTypeIndex].source =
              docType === 'VIDEO' ? resp.videoFile : resp.supportMaterial;
          }
          return documentEntry;
        }
      );
      setUpdatedEpisodesTableCategoriesValues(updatedEpisodesTableCategories);
      enqueueSnackbar(
        t('education.apiMessages.educationEpisodeDocumentUploadSuccess'),
        createOpts('success') as any
      );
      isLoading(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        t('education.apiMessages.educationEpisodeDocumentUploadError'),
        createOpts('error') as any
      );
      isLoading(false);
    }
  };
  const onEpisodeDocumentDiscard = async (
    documentIndex: number,
    fileTypeIndex: number,
    docType: 'VIDEO' | 'SLIDES'
  ) => {
    try {
      // Handling the scenario where the episode is only saved locally.
      const educationEpisodeId = episodesTableCategoriesRef.current[documentIndex].id;
      if (!educationEpisodeId) {
        const newEpisodeTableCategories = episodesTableCategoriesRef.current.map(
          (documentEntry, index) => {
            if (index === documentIndex) {
              documentEntry.tableEntries[fileTypeIndex].source = null;
            }
            return documentEntry;
          }
        );
        setUpdatedEpisodesTableCategoriesValues(newEpisodeTableCategories);
        return;
      }
      isLoading(true);
      // Handling the scenario where the episode is server side provided
      const payload = {
        id: categoryId,
        episode_id: educationEpisodeId,
      };
      if (docType === 'VIDEO') {
        await deleteEducationEpisodeVideo(payload);
      } else if (docType === 'SLIDES') {
        await deleteEducationEpisodeDocument(payload);
      }
      setUpdatedEpisodesTableCategoriesValues(
        [...episodesTableCategoriesRef.current].map((documentEntry, index) => {
          if (index === documentIndex) {
            documentEntry.tableEntries[fileTypeIndex].source = null;
          }
          return documentEntry;
        })
      );
      enqueueSnackbar(
        t('education.apiMessages.educationEpisodeDocumentDiscardSuccess'),
        createOpts('success') as any
      );
      isLoading(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        t('education.apiMessages.educationEpisodeDocumentDiscardError'),
        createOpts('error') as any
      );
      isLoading(false);
    }
  };
  const onEpisodeDeletetion = async (documentIndex: number) => {
    try {
      // Handling the case where the episode is only added locally
      const educationEpisodeId = episodesTableCategoriesRef.current[documentIndex].id;
      if (!educationEpisodeId) {
        setUpdatedEpisodesTableCategoriesValues(
          episodesTableCategoriesRef.current.filter((elem, index) => index !== documentIndex)
        );
        return;
      }
      // Handling the scenario where the episode is server side provided
      isLoading(true);
      await deleteEducationEpisode({ id: categoryId, episode_id: educationEpisodeId });
      setUpdatedEpisodesTableCategoriesValues(
        episodesTableCategoriesRef.current.filter((elem, index) => index !== documentIndex)
      );
      enqueueSnackbar(
        t('education.apiMessages.educationEpisodeDeleteSuccess'),
        createOpts('success') as any
      );
      isLoading(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        t('education.apiMessages.educationEpisodeDeleteError'),
        createOpts('error') as any
      );
      isLoading(false);
    }
  };

  const onEpisodeFormValueChange = async (
    documentIndex,
    values: { title: string; position: number }
  ) => {
    setUpdatedEpisodesTableCategoriesValues(
      episodesTableCategoriesRef.current.map((item, index) => {
        if (index === documentIndex) {
          return {
            ...item,
            title: values.title,
            position: values.position,
          };
        }
        return item;
      })
    );
  };

  const onSaveEpisode = async (documentIndex: number) => {
    const episodeData = episodesTableCategoriesRef.current[documentIndex];
    const { id: episodeId } = episodeData;
    const videoEntry = episodeData.tableEntries.find((item) => item.type === 'VIDEO');
    const supportMaterial = episodeData.tableEntries.find((item) => item.type === 'SLIDES');
    const payload = {
      id: categoryId,
      title: episodeData.title,
      position: episodeData.position,
      videoFile: videoEntry?.source as Blob,
      supportMaterial: supportMaterial?.source as Blob,
    };
    if (!episodeData.isValid) {
      enqueueSnackbar(
        t('education.createMaterial.educationCategoryFixFormIssues'),
        createOpts('error') as any
      );
      return;
    }
    if (typeof videoEntry?.source === 'string') {
      delete payload.videoFile;
    }
    if (typeof supportMaterial?.source === 'string') {
      delete payload.supportMaterial;
    }
    if (!payload.title || !payload.position || (!videoEntry?.source && !supportMaterial?.source)) {
      enqueueSnackbar(
        t('education.apiMessages.educationCategorySaveEpisodesMissingContentError'),
        createOpts('error') as any
      );
      return;
    }
    try {
      isLoading(true);
      let resp = null;
      if (episodeId) {
        resp = await updateEducationEpisode({ ...payload, episode_id: episodeId });
      } else {
        resp = await addEducationEpisode({ ...payload, videoFile: null });
        if (payload.videoFile) {
          const { url } = await getEducationMaterialVideoUploadUrl({
            id: categoryId,
            episode_id: resp.id,
          });
          const uploadResp = await axios({
            method: 'put',
            url: url,
            data: payload.videoFile,
            headers: {
              'Content-Type': payload.videoFile.type,
            },
          });
          console.log(uploadResp);
          resp = await confirmEducationMaterialVideoUpload({
            id: categoryId,
            episode_id: resp.id,
          });
        }
      }

      setUpdatedEpisodesTableCategoriesValues(
        episodesTableCategoriesRef.current.map((item, index) => {
          if (index === documentIndex) {
            item.id = resp.id;
            item.tableEntries = item.tableEntries.map((tableEntry) => {
              if (tableEntry.source instanceof Blob) {
                tableEntry.source =
                  tableEntry.type === 'VIDEO' ? resp.videoFile : resp.supportMaterial;
              }
              return tableEntry;
            });
          }
          return { ...item };
        })
      );
      enqueueSnackbar(
        episodeId
          ? t('education.apiMessages.educationCategoryUpdateEpisodeSuccess')
          : t('education.apiMessages.educationCategoryCreateEpisodeSuccess'),
        createOpts('success') as any
      );
    } catch (err) {
      enqueueSnackbar(
        episodeId
          ? t('education.apiMessages.educationCategoryUpdateEpisodesError')
          : t('education.apiMessages.educationCategoryCreateEpisodesError'),
        createOpts('error') as any
      );
    } finally {
      isLoading(false);
    }
  };
  const onEpisodeValidityChange = (documentIndex: number, isValid: boolean) => {
    setUpdatedEpisodesTableCategoriesValues(
      episodesTableCategoriesRef.current.map((item, index) => {
        if (index === documentIndex) {
          return {
            ...item,
            isValid: isValid,
          };
        }
        return item;
      })
    );
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <Box>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: '20px', mt: '20px' }}>
          {t('education.createMaterial.documents')}
        </Typography>
        {episodesTableCategoriesRef.current && episodesTableCategoriesRef.current.length && (
          <CreateEducationDocumentsTable
            onFormValueChange={onEpisodeFormValueChange}
            onDocumentUpload={onEpisodeDocumentUpload}
            onEpisodeDeletion={onEpisodeDeletetion}
            onDocumentDiscard={onEpisodeDocumentDiscard}
            onEpisodeValidityChange={onEpisodeValidityChange}
            uploadedDocuments={episodesTableCategoriesRef.current}
            onSaveEpisode={onSaveEpisode}
          />
        )}
      </Box>
      <Button sx={{ mt: 2 }} variant="outlined" fullWidth={false} onClick={onAddRow.bind(this)}>
        {t('education.createMaterial.addMore')}
      </Button>
    </Box>
  );
};
