import { Box } from '@mui/material';
import { DocumentsTable, RowCellType } from 'components/generic/Table';
import type { Document } from 'types/document';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignPresentationFileType } from 'types/campaign';
import { CreateEducationFileEntry } from './CreateEducationFileEntry';
import { useModal } from 'mui-modal-provider';
import { FileUploadModal } from 'components/dashboard/FileUploadModal';
import { useSnackbar } from 'notistack';
import { createOpts } from 'snacks';

export interface CreateEducationDocumentTableEntry {
  id: number | null;
  source: string | File;
  title: string | null;
  type: 'VIDEO' | 'SLIDES';
}
export interface CreateEducationCoverImageTableProps {
  uploadedDocument: CreateEducationDocumentTableEntry;
  onCoverImageUpload: (file: File) => void;
  onCoverImageDiscard?: () => void;
}

export const CreateEducationCoverImageTable: FC<CreateEducationCoverImageTableProps> = (
  props: CreateEducationCoverImageTableProps
): JSX.Element => {
  const { uploadedDocument, onCoverImageUpload, onCoverImageDiscard } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const onUploadEducationCategoryCover = () => {
    showModal(FileUploadModal, {
      modalData: {
        type: 'COVER',
        label: '',
      },
      pickOnly: true,
      acceptUploadFileType: '.jpg,.png',
      onPickFile: (file) => {
        if (file) {
          const fileSizeInMb = file.size / 1024 / 1024;
          if (fileSizeInMb > 2) {
            enqueueSnackbar(
              t('education.createMaterial.educationMaterialCoverImageMaxPhotoSize'),
              createOpts('error') as any
            );
            return;
          }
          onCoverImageUpload(file);
        }
      },
    });
  };

  const onDiscardCampaignDocument = () => {
    if (onCoverImageDiscard) {
      onCoverImageDiscard();
    }
  };

  return (
    <Box>
      {uploadedDocument && (
        <CreateEducationFileEntry
          handleUpload={onUploadEducationCategoryCover}
          handleDiscard={onDiscardCampaignDocument}
          educationDocument={uploadedDocument}
          withDiscard={false}
        ></CreateEducationFileEntry>
      )}
    </Box>
  );
};
