// import { useAuth } from 'hooks';
import { withErrorSuspense } from 'utils/withErrorSuspense';
import { useEffect } from 'react';
import gtm from '../../../lib/gtm';
import { GTM_EVENTS } from '../../../constants';
import { Button, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useGetEducationEpisodes } from 'api/hooks/useGetEducationalEpisodes';
import { useParams } from 'react-router';
import { useGetEducationalCategory } from 'api/hooks/useGetEducationalCategory';
import { useModal } from 'mui-modal-provider';
import { InvestorEducationViewVideoModal } from './InvestorEducationVIewVideoModal';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { colors } from 'theme';
import { downloadEducationSupportFile } from 'api/downloadEducationFile';

const InvestorEducationCategoryDetailsPage = () => {
  const { t } = useTranslation();
  const { categoryId: educationCategoryId } = useParams();
  const { data: category } = useGetEducationalCategory(+educationCategoryId);
  const { data: educationEpisodes } = useGetEducationEpisodes(+educationCategoryId);
  const { showModal } = useModal();
  const isXs = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    gtm.push({ event: GTM_EVENTS.PAGE_VIEW, pageTitle: 'Investor Education Details' });
  }, []);

  const downloadSupportMaterial = async (episode) => {
    const url = episode.supportMaterial;
    const res = await downloadEducationSupportFile(url);
    console.log(res);
    const contentDisposition = res.headers['content-disposition']
      .split(';')
      .map((keyValuePair) => {
        const [key, value] = keyValuePair.split('=');
        return {
          [key.trim()]: value,
        };
      })
      .reduce((acc, val) => {
        return { ...acc, ...val };
      }, {});
    const backupName = url.split('/').pop().split('#')[0].split('?')[0];
    const filename = contentDisposition.filename || backupName;
    const supportMaterial = res.data as BlobPart;

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(
      new Blob([supportMaterial], { type: 'application/octet-stream' })
    );
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      link.remove();
    }, 200);
    // window.open(episode.supportMaterial, '_blank');
  };
  const viewVideo = (episode) => {
    showModal(InvestorEducationViewVideoModal, {
      title: episode.title,
      videoUrl: episode.videoFile,
    });
  };
  console.log('categorycategorycategorycategorycategory: ', category);
  return (
    <Box>
      <Box sx={{ width: '100%', position: 'relative', borderRadius: '9px' }}>
        <img
          style={{
            maxHeight: '360px',
            width: '100%',
            objectFit: 'cover',
            overflow: 'hidden',
            borderRadius: '9px',
          }}
          src={category?.coverImage}
        />
      </Box>
      <Box>
        <Typography variant="h3" fontWeight="bold" sx={{ mt: '16px', mb: '32px' }}>
          {category?.title}
        </Typography>
        <Typography variant="h2" sx={{ mb: '32px' }}>
          {category?.description}
        </Typography>
      </Box>
      {educationEpisodes.length === 0 ? (
        <Box>
          <Typography sx={{ mb: '32px' }}>{t('education.investorEducation.noLessons')}</Typography>
        </Box>
      ) : (
        <Box>
          {!isXs && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '8px',
                borderBottom: '1px solid #d3d3d3',
              }}
            >
              <Box sx={{ flex: '1 1 100px' }}>
                <Typography fontWeight="bold">
                  {t('education.investorEducationTable.no')}
                </Typography>
              </Box>
              <Box sx={{ flex: '1 1 calc(100% - 50px - 100px)' }}>
                <Typography fontWeight="bold">
                  {t('education.investorEducationTable.episodeName')}
                </Typography>
              </Box>
              <Box sx={{ flex: '1 1 100px' }}></Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', pb: isXs ? '64px' : 0 }}>
            {educationEpisodes.map((episode) => (
              <Box
                key={episode.id}
                sx={{
                  display: 'flex',
                  flexDirection: isXs ? 'column' : 'row',
                  padding: '8px',
                  borderBottom: '1px solid #d3d3d3',
                  '> div': isXs ? { padding: '8px' } : {},
                }}
              >
                <Box sx={{ display: 'flex', flex: isXs ? '1' : '1 1 100px' }}>
                  {isXs && (
                    <Typography
                      sx={{ display: 'flex', flex: isXs ? '1' : '1 1 50px' }}
                      fontWeight="bold"
                    >
                      {episode.position} - {episode.title}
                    </Typography>
                  )}
                  {!isXs && <Box>{episode.position}</Box>}
                </Box>
                {!isXs && (
                  <Box sx={{ display: 'flex', flex: isXs ? '1' : '1 1 calc(100% - 50px - 100px)' }}>
                    <Box
                      sx={
                        isXs
                          ? { justifyContent: 'flex-end', display: 'flex', textAlign: 'end' }
                          : {}
                      }
                    >
                      <Typography fontStyle="italic">{episode.title}</Typography>
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: isXs ? '1' : '1 1 100px',
                  }}
                >
                  {/* {isXs && (
                    <Typography
                      sx={{ display: 'flex', flex: isXs ? '1' : '1 1 50px' }}
                      fontWeight="bold"
                    >
                      {t('education.investorEducationTable.actions')}
                    </Typography>
                  )} */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                      justifyContent: isXs ? 'flex-end' : '',
                      '> div': { ml: '8px', width: '24px' },
                    }}
                  >
                    <Box
                      sx={{
                        visibility: episode.supportMaterial ? 'visible' : 'hidden',
                        cursor: 'pointer',
                        color: colors.font.blue,
                      }}
                      onClick={() => downloadSupportMaterial(episode)}
                    >
                      <Tooltip title={t('education.investorEducationTable.supportMaterialTooltip')}>
                        <PictureAsPdfIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        visibility: episode.videoFile ? 'visible' : 'hidden',
                        cursor: 'pointer',
                        color: colors.font.blue,
                      }}
                      onClick={() => viewVideo(episode)}
                    >
                      <Tooltip title={t('education.investorEducationTable.playVideoTooltip')}>
                        <PlayArrowIcon />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withErrorSuspense(InvestorEducationCategoryDetailsPage);
