import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Button, Typography, useMediaQuery } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { InvestorIncompleteProfileModal } from './InvestorIncompleteProfileModal';
import { useGetUserById } from 'api/hooks/useGetCurrentUser';
import { useAuth } from 'hooks';
import { useModal } from 'mui-modal-provider';
import { useNavigate } from 'react-router';
import { Routes } from '../../../../../../../constants';
import globalEventManager from 'utils/global-event-manager';
import InfoModal from 'components/generic/InfoModal';

export const InvestorProfileReminderComplete: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const currentUserRole = user?.role;
  const currentUserId = user?.id;
  const { data, mutate } = useGetUserById(currentUserId);
  const { showModal } = useModal();
  const navigate = useNavigate();
  const isXs = useMediaQuery('(max-width:600px)');
  const [modalDisplayed, setModalDisplayed] = useState(
    Boolean(parseInt(sessionStorage.getItem('modalDisplayed')))
  );
  const onRedirectClick = () => {
    navigate(`${Routes.InvestorProfile}?tab=investorProfile`);
  };
  const refreshData = useCallback(() => {
    mutate();
  }, []);

  useEffect(() => {
    globalEventManager.addListener('refresh-user', refreshData);
    return () => {
      globalEventManager.removeListener('refresh-user', refreshData);
    };
  }, []);

  useEffect(() => {
    if (currentUserRole !== 'INVESTOR') {
      return;
    }
    if (!data || data.userCategory !== 'UNCATEGORIZED') {
      return;
    }
    if (modalDisplayed) {
      return;
    }
    setModalDisplayed(true);
    try {
      if (sessionStorage) {
        sessionStorage.setItem('modalDisplayed', '1');
      }
    } catch (e) {
      console.error('Session storage is not available');
    }

    showModal(InvestorIncompleteProfileModal, {
      onModalClose: () => {},
    });
  }, [currentUserRole, data]);

  if (currentUserRole !== 'INVESTOR' || !data || data.userCategory !== 'UNCATEGORIZED') {
    return <></>;
  }
  const showInfo = () => {
    showModal(InfoModal, {
      text: t('investorIncompleteProfileModal.infoText'),
      customStyles: { height: 'auto', width: 'auto' },
    });
  };
  return (
    <Box
      sx={{
        padding: '0',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        ...(isXs
          ? {
              padding: 0,
              mb: '22px',
            }
          : {}),
      }}
    >
      <Box sx={{ display: 'flex', ...(isXs ? { alignItems: 'center' } : {}) }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '30px',
            height: '30px',
            fontSize: '19px',
            border: `1px solid red`,
            color: 'red',
            padding: '16px',
            cursor: 'pointer',
            marginLeft: '16px',
            borderRadius: '4px',
            fontWeight: 'bold',
            m: '8px',
            ...(isXs ? { mr: '8px' } : {}),
          }}
          onClick={() => showInfo()}
        >
          i
        </Box>
        <Typography
          sx={{ color: 'red', ...(!isXs ? { display: 'flex', alignItems: 'center' } : {}) }}
        >
          {t('investorIncompleteProfileModal.text')}
        </Typography>
      </Box>
      <Button
        onClick={onRedirectClick}
        size="small"
        sx={{ ml: '16px', ...(isXs ? { ml: '30px', mt: '16px' } : {}) }}
        variant="contained"
      >
        {t('investorIncompleteProfileModal.redirect')}
      </Button>
    </Box>
  );
};
