import { EducationEpisode } from 'types/education';
import { EdexClientJava } from '../lib/axios';

export const confirmEducationMaterialVideoUpload = async ({
  id,
  episode_id,
}: {
  id: number;
  episode_id: number;
}): Promise<EducationEpisode> => {
  try {
    const client = await EdexClientJava.getClient();
    const { data } = await client.educationControllerConfirmVideoUpload({ id, episode_id });
    return data as EducationEpisode;
  } catch (err) {
    throw new Error(err);
  }
};
