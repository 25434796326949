import { getCurrentUser } from 'api/hooks/useGetCurrentUser';
import Keycloak, { KeycloakPromise } from 'keycloak-js';
import { UserOutputSingleDto } from 'types/user';
import { keycloakConfigUrl } from './getApiUrlByEnv';

const keycloakConfigPath = keycloakConfigUrl;
const _kc = Keycloak(keycloakConfigPath);
let loggedInUser: UserOutputSingleDto | null = null;
let userRegistrationSubmittedSuccesfully = false;
let keycloakProvidedLocale: string = null;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (
  onAuthenticatedCallback: Function,
  setCurrentUser: Function,
  locale
): void => {
  try {
    sessionStorage.clear();
  } catch (e) {
    console.error('Session storage is not available');
  }
  _kc
    .init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
      enableLogging: true,
      checkLoginIframe: false,
    })
    .then(async (authenticated) => {
      if (!authenticated) {
        doLogin({ locale });
      }
      if (authenticated) {
        keycloakProvidedLocale = _kc.tokenParsed.locale;
        automaticalyUpdateToken();
        try {
          loggedInUser = await getCurrentUser();
          setCurrentUser(loggedInUser);
        } catch (e) {
          console.warn('User has not completed registration');
          console.error(e);
        }
      }
      onAuthenticatedCallback(authenticated, _kc);
    })
    .catch(console.error);
};

const automaticalyUpdateToken = () => {
  _kc.onTokenExpired = () => {
    _kc.updateToken(30);
  };
};
const setUserRegistrationSubmittedSuccesfully = (value) => {
  userRegistrationSubmittedSuccesfully = value;
};
const doLogin = _kc.login;

const doLogout = _kc.logout;
const getToken = (): string | null => _kc.token;
const isLoggedIn = (): boolean => !!_kc.token;
const loadLoggedInUser = async (withError): Promise<UserOutputSingleDto | null> => {
  loggedInUser = await getCurrentUser(withError);
  return loggedInUser;
};

// eslint-disable-next-line
const updateToken = (successCallback, errorCallback?) =>
  _kc
    .updateToken(1000000)
    .then(successCallback)
    .catch(errorCallback || doLogin);

const updateTokenForAxios = (): KeycloakPromise<boolean, boolean> => _kc.updateToken(30);

const getUsername = (): string => _kc.tokenParsed?.preferred_username;

const getUserId = (): string => _kc.subject;
const getLoggedInUser = (): UserOutputSingleDto | null => loggedInUser;
const userNeedsRegistrationCompletion = (): boolean =>
  isLoggedIn() && !getLoggedInUser() && !userRegistrationSubmittedSuccesfully;
const hasRole = (roles: string[]): boolean => roles.some((role) => _kc.hasRealmRole(role));

const getUserFirstName = (): string => _kc.tokenParsed.given_name;
const getUserLastName = (): string => _kc.tokenParsed.family_name;

const updatePassword = (locale: string): void => {
  const url =
    _kc.createAccountUrl().replace('/account?', '/account/password?') + '&kc_locale=' + locale;
  window.location.href = url;
};

const accountManagement = (locale: string): void => {
  const url =
    _kc.createAccountUrl().replace('/account?', '/account/identity?') + '&kc_locale=' + locale;
  window.location.href = url;
};

const KeycloakUserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserId,
  getLoggedInUser,
  userNeedsRegistrationCompletion,
  getUserFirstName,
  getUserLastName,
  loadLoggedInUser,
  updatePassword,
  accountManagement,
  updateTokenForAxios,
  keycloakProvidedLocale,
  userRegistrationSubmittedSuccesfully,
  setUserRegistrationSubmittedSuccesfully,
};

export default KeycloakUserService;
