import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import type { FC } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import ModalProvider from 'mui-modal-provider';
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import './i18n/i18n';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import { useAuth, useInitAppDependencies } from 'hooks';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import ReactGA from 'react-ga4';
import routes from './routes';
import { createCustomTheme } from './theme';
import { useEventsContext } from './hooks';
import KeycloakUserService from 'utils/KeycloakUserService';
import { Routes } from './constants';
import useCurrentLanguage from 'hooks/useCurrentLanguage';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const navigate = useNavigate();
  const { doLogout, doCompleteRegistration } = useEventsContext();
  const { setCurrentUser, user } = useAuth();
  const { initialized } = useInitAppDependencies(setCurrentUser);
  const [currentLanguage] = useCurrentLanguage();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
    console.log('GTM initialized');
    ReactGA.initialize('G-HLS1RV265R');
    console.log('GA-4 Initialized');
  }, []);

  useEffect(() => {
    if (doLogout) {
      KeycloakUserService.doLogout().then(() =>
        KeycloakUserService.doLogin({ locale: currentLanguage })
      );
    }
    if (doCompleteRegistration) {
      navigate(Routes.FinishRegistration);
    }
  }, [doLogout, doCompleteRegistration]);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <SWRConfig
      value={{
        suspense: true,
      }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <RTL direction={settings.direction}>
              <CssBaseline />
              {(initialized && !!user) || (initialized && doCompleteRegistration) ? (
                content
              ) : (
                <SplashScreen />
              )}
            </RTL>
          </ModalProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </SWRConfig>
  );
};

export default App;
