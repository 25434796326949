import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useAuth } from 'hooks';
import useCurrentLanguage from 'hooks/useCurrentLanguage';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ASFDemoRestriction: FC<any> = ({ children }): JSX.Element => {
  const { user, logout } = useAuth();
  const [ASF_DEMO] = useState(false);
  const { t } = useTranslation();
  const [currentLanguage] = useCurrentLanguage();

  // If ASF_DEMO is false, it means we are not in the asf demo process
  // and we don't display any warning to the user.
  if (!ASF_DEMO) {
    return children;
  }
  // If user is not logged in we display the UI as always
  if (!user) {
    return children;
  }
  if (user?.email?.endsWith('@weronin.com')) {
    return children;
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white',
        zIndex: 9999999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
      }}
    >
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{t('asfDemo.title')}</p>
      <Button color="primary" sx={{ mb: 3 }} variant="text" onClick={() => logout(currentLanguage)}>
        {t('sidebar.logout')}
      </Button>
    </Box>
  );
};
