import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import UserRoleGuard from './components/UserRoleGuard';
import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from './components/dashboard/DashboardLayout';
import InvestmentCampaignGuard from 'components/InvestmentCampaignGuard';
import InvestmentCampaignDetailsPreviewGuard from 'components/InvestmentCampaignDetailsPreviewGuard';
import GuestGuard from 'components/GuestGuard';
import InvestmentCampaignSubscribeGuard from 'components/InvestmentCampaignSubscribeGuard';
import InvestorInvestmentEducationGuard from 'components/InvestorInvestmentEducationGuard';
import CreateEducationMaterial from 'pages/dashboard/manager/education/CreateEducationMaterial.swr';
import InvestorEducationCategoryDetailsPage from 'pages/dashboard/investor/InvestorEducationCategoryDetailsPage.swr';
import { InvestorProfileReminderCompleteWrapper } from 'components/dashboard/shared/investor-profile/investor-details-tabs/investor-profile-tab/components/InvestorProfileReminderWrapper';

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <InvestorProfileReminderCompleteWrapper />
      <Component {...props} />
    </Suspense>
  );
};

// Authentication pages
const FinishRegisterKeycloak = Loadable(
  lazy(() => import('./pages/authentication/FinishRegisterKeycloak'))
);

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const InvestorWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/investors/InvestorsWorkspace.swr'))
);

const InvestorOnboardProfile = Loadable(
  lazy(() => import('./pages/dashboard/manager/investors/InvestorProfileWorkspace.swr'))
);

const CampaignDetails = Loadable(
  lazy(() => import('./pages/dashboard/issuer/CampaignDetails.swr'))
);
const IssuerWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/issuers/IssuersWorkspace.swr'))
);
const IssuerOnboardProfile = Loadable(
  lazy(() => import('./pages/dashboard/manager/issuers/IssuerProfileWorkspace.swr'))
);

const CampaignsWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/campaigns/CampaignsWorkspace.swr'))
);

const CampaignDetailsWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/campaigns/CampaignDetailsWorkspace.swr'))
);

const CampaignPriorityList = Loadable(
  lazy(() => import('./pages/dashboard/manager/campaigns/CampaignPriorityList.swr'))
);

const InvestmentsWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/investments/InvestmentsWorkspace.swr'))
);
const EducationWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/education/EducationWorkspace.swr'))
);
const IssuerProfile = Loadable(lazy(() => import('./pages/dashboard/issuer/IssuerProfile.swr')));
const InvestorProfile = Loadable(
  lazy(() => import('./pages/dashboard/investor/InvestorProfile.swr'))
);

const InvestorInvestments = Loadable(
  lazy(() => import('./pages/dashboard/investor/InvestorInvestments.swr'))
);
const InvestorEducation = Loadable(
  lazy(() => import('./pages/dashboard/investor/InvestorEducation.swr'))
);

const InvestorInvestmentDetails = Loadable(
  lazy(() => import('./pages/dashboard/investor/InvestorInvestmentDetails.swr'))
);

const InvestorInvestmentDetailsWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/investments/InvestorInvestmentDetailsWorkspace.swr'))
);

const InvestmentCampaignDetails = Loadable(
  lazy(() => import('./pages/dashboard/investor/InvestmentCampaignDetails.swr'))
);
const InvestmentCampaignSubscribe = Loadable(
  lazy(() => import('./pages/dashboard/investor/InvestmentCampaignSubscribe.swr'))
);

const InvestmentCampaignDetailsWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/investments/InvestmentCampaignDetailsWorkspace.swr'))
);

const InvestmentWizard = Loadable(
  lazy(() => import('./pages/dashboard/investor/InvestmentWizard.swr'))
);

const InvestmentWizardFinish = Loadable(
  lazy(() => import('./pages/dashboard/investor/InvestmentWizardFinish.swr'))
);

const NewsFeedWorkspace = Loadable(
  lazy(() => import('./pages/dashboard/manager/news-feed/NewsFeedWorkspace.swr'))
);

const NewsFeed = Loadable(lazy(() => import('./pages/dashboard/news-feed/NewsFeed.swr')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'finish-register',
        element: (
          <GuestGuard>
            <FinishRegisterKeycloak />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'investors-workspace',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <InvestorWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'education-workspace',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <EducationWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'create-education-material',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <CreateEducationMaterial />
          </UserRoleGuard>
        ),
      },
      {
        path: 'edit-education-material/:categoryId',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <CreateEducationMaterial />
          </UserRoleGuard>
        ),
      },

      {
        path: 'investors-workspace/:userId',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <InvestorOnboardProfile />
          </UserRoleGuard>
        ),
      },
      {
        path: 'investors-workspace/:userId/investment/:id',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <InvestorInvestmentDetails />
          </UserRoleGuard>
        ),
      },
      {
        path: 'issuers-workspace',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <IssuerWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'issuers-workspace/:userId',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <IssuerOnboardProfile />
          </UserRoleGuard>
        ),
      },
      {
        path: 'campaigns-workspace',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <CampaignsWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'campaigns-workspace/:campaignId',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <CampaignDetailsWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'campaigns-workspace/:campaignId/priority',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <CampaignPriorityList />
          </UserRoleGuard>
        ),
      },
      {
        path: 'investments-workspace',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <InvestmentsWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'investments-workspace/:investmentId',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <InvestorInvestmentDetailsWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'crowdfunding-campaign',
        element: <UserRoleGuard roles={['ADMIN', 'ISSUER']}>{<CampaignDetails />}</UserRoleGuard>,
      },
      {
        path: 'crowdfunding-campaign/:campaignId',
        element: <UserRoleGuard roles={['ADMIN', 'ISSUER']}>{<CampaignDetails />}</UserRoleGuard>,
      },
      {
        path: 'company-profile',
        element: (
          <UserRoleGuard roles={['ISSUER']}>
            <IssuerProfile />
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-profile',
        element: (
          <UserRoleGuard roles={['INVESTOR']}>
            <InvestorProfile />
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-investments',
        element: (
          <UserRoleGuard roles={['INVESTOR']}>
            <InvestorInvestments />
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-education',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER', 'INVESTOR']}>
            <InvestorInvestmentEducationGuard>
              <InvestorEducation />
            </InvestorInvestmentEducationGuard>
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-education/:categoryId',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER', 'INVESTOR']}>
            <InvestorInvestmentEducationGuard>
              <InvestorEducationCategoryDetailsPage />
            </InvestorInvestmentEducationGuard>
          </UserRoleGuard>
        ),
      },
      {
        path: 'investment-wizard/:campaignId',
        element: (
          <UserRoleGuard roles={['INVESTOR', 'ADMIN']}>
            <InvestmentCampaignGuard status={['LISTED', 'PRIORITY_INVESTMENT']}>
              <InvestmentWizard />
            </InvestmentCampaignGuard>
          </UserRoleGuard>
        ),
      },
      {
        path: 'investment-wizard/finish',
        element: (
          <UserRoleGuard roles={['INVESTOR', 'ADMIN']}>
            <InvestmentWizardFinish />
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-investments/:investmentId',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER', 'INVESTOR']}>
            <InvestorInvestmentDetails />
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-campaign/:campaignId',
        element: (
          <UserRoleGuard roles={['INVESTOR', 'ISSUER', 'ADMIN', 'ACCOUNT_MANAGER']}>
            <InvestmentCampaignDetailsPreviewGuard>
              <InvestmentCampaignDetails />
            </InvestmentCampaignDetailsPreviewGuard>
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-campaign/new/:campaignId',
        element: (
          <UserRoleGuard roles={['INVESTOR', 'ISSUER', 'ADMIN', 'ACCOUNT_MANAGER']}>
            <InvestmentCampaignDetailsPreviewGuard>
              <InvestmentCampaignDetails />
            </InvestmentCampaignDetailsPreviewGuard>
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-campaign/:campaignId/subscribe',
        element: (
          <UserRoleGuard roles={['INVESTOR']}>
            <InvestmentCampaignSubscribeGuard>
              <InvestmentCampaignSubscribe />
            </InvestmentCampaignSubscribeGuard>
          </UserRoleGuard>
        ),
      },
      {
        path: 'investor-campaign-workspace/:campaignId',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <InvestmentCampaignDetailsWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'news-feed-workspace',
        element: (
          <UserRoleGuard roles={['ADMIN', 'ACCOUNT_MANAGER']}>
            <NewsFeedWorkspace />
          </UserRoleGuard>
        ),
      },
      {
        path: 'news-feed',
        element: (
          <UserRoleGuard roles={['INVESTOR', 'ISSUER']}>
            <NewsFeed />
          </UserRoleGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export const getRouteName = (currentRoute: string, user?): string => {
  const routeNames = [
    {
      regex: /^\/dashboard$/,
      name: user && user.role === 'INVESTOR' ? 'routeNames.campaigns' : 'routeNames.overview',
    },
    { regex: /^\/dashboard\/account$/, name: 'routeNames.yourAccount' },
    { regex: /^\/dashboard\/issuers-workspace$/, name: 'routeNames.issuersWorkspace' },
    { regex: /^\/dashboard\/issuers-workspace\/\d+$/, name: 'routeNames.issuerOnboardingProfile' },
    { regex: /^\/dashboard\/investors-workspace$/, name: 'routeNames.investorsWorkspace' },
    {
      regex: /^\/dashboard\/investors-workspace\/\d+$/,
      name: 'routeNames.investorOnboardingProfile',
    },
    {
      regex: /^\/dashboard\/investors-workspace\/\d+\/investment\/\d+$/,
      name: 'routeNames.investmentDetails',
    },
    { regex: /^\/dashboard\/campaigns-workspace$/, name: 'routeNames.campaignsWorkspace' },
    { regex: /^\/dashboard\/campaigns-workspace\/\d+$/, name: 'routeNames.campaignDetails' },
    {
      regex: /^\/dashboard\/campaigns-workspace\/\d+\/priority/,
      name: 'routeNames.campaignPriorityList',
    },
    { regex: /^\/dashboard\/projects-workspace$/, name: 'routeNames.projectsWorkspace' },
    { regex: /^\/dashboard\/investments-workspace$/, name: 'routeNames.investmentsWorkspace' },
    { regex: /^\/dashboard\/investments-workspace\/\d+$/, name: 'routeNames.investmentDetails' },
    { regex: /^\/dashboard\/crowdfunding-campaign$/, name: 'routeNames.startCampaign' },
    { regex: /^\/dashboard\/company-profile$/, name: 'routeNames.companyProfile' },
    { regex: /^\/dashboard\/investor-profile$/, name: 'routeNames.investorProfile' },
    { regex: /^\/dashboard\/investor-investments$/, name: 'routeNames.myInvestments' },
    { regex: /^\/dashboard\/investor-investments\/\d+$/, name: 'routeNames.investmentDetails' },
    { regex: /^\/dashboard\/investor-campaign\/\d+$/, name: 'routeNames.crowdfundingCampaign' },
    { regex: /^\/dashboard\/investor-campaign\/new\/\d+$/, name: 'routeNames.comingSoon' },
    {
      regex: /^\/dashboard\/investor-campaign-workspace\/\d+$/,
      name: 'routeNames.crowdfundingCampaign',
    },
    { regex: /^\/dashboard\/investor-education$/, name: 'routeNames.education' },
    { regex: /^\/dashboard\/investor-education\/\d+$/, name: 'routeNames.educationMaterial' },
    { regex: /^\/dashboard\/education-workspace$/, name: 'routeNames.education' },
    {
      regex: /^\/dashboard\/create-education-material$/,
      name: 'routeNames.createEducationMaterial',
    },
    {
      regex: /^\/dashboard\/edit-education-material\/\d+$/,
      name: 'routeNames.editEducationMaterial',
    },
    { regex: /^\/dashboard\/investment-wizard\/\d+$/, name: 'routeNames.investmentWizard' },
    { regex: /^\/dashboard\/investment-wizard\/finish$/, name: 'routeNames.investmentWizard' },
    { regex: /^\/dashboard\/news-feed-workspace$/, name: 'routeNames.newsFeedWorkspace' },
    { regex: /^\/dashboard\/news-feed$/, name: 'routeNames.newsFeed' },
  ];
  for (const route of routeNames) {
    if (route.regex.test(currentRoute)) return route.name;
  }
  return null;
};

export default routes;
