class GlobalEventManager {
  listeners: { [key: string]: Function[] } = {};
  constructor() {}

  addListener(eventName, listener) {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [listener];
      return;
    }
    this.listeners[eventName].push(listener);
  }
  dispatchEvent(event, payload) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((element) => {
        element(payload);
      });
    }
  }

  removeListener(event, callback) {
    this.listeners[event] = this.listeners[event].filter((func) => func !== callback);
  }
}

export default new GlobalEventManager();
