import type { UserOutputSingleDto } from 'types/user';
import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

export interface InvestorIncompleteProfileModalProps {
  onModalClose: () => void;
}

export const InvestorIncompleteProfileModal: FC<InvestorIncompleteProfileModalProps> = (
  props: InvestorIncompleteProfileModalProps
) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { onModalClose } = props;

  const onCancel = () => {
    setOpen(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  return (
    <Dialog sx={{ maxWidth: '470px', m: 'auto' }} open={open} onClose={onCancel} maxWidth={false}>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CloseIcon onClick={onCancel} sx={{ cursor: 'pointer', width: '30px', height: '30px' }} />
        </Box>
        <Box sx={{ pr: '32px' }}>
          <Typography>{t('investorIncompleteProfileModal.text')}</Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
