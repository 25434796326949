import { EdexClientJava } from 'lib/axios';
import { SWRHook } from '../../hooks/hook.types';
import { SwrKeys } from 'swrKeys';
import useSWR from 'swr';
import { UserOutputSingleDto } from 'types/user';

export const getCurrentUser = async (withError = true): Promise<UserOutputSingleDto> => {
  try {
    const client = await EdexClientJava.getClient();
    const response = await client.userControllerGetCurrentUser();
    return response.data as UserOutputSingleDto;
  } catch (err) {
    if (!withError) {
      return null;
    }
    throw new Error(err);
  }
};

export function useGetUserById(id: number): SWRHook<UserOutputSingleDto> {
  const { data, error, mutate } = useSWR([SwrKeys.useGetCurrentUser, id], getCurrentUser);
  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
}
