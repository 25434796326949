import { EdexClientJava } from 'lib/axios';
import { SWRHook } from '../../hooks/hook.types';
import useSWR from 'swr';
import { EducationCategory } from 'types/education';
import { SwrKeys } from 'swrKeys';

export const getEducationCategories = async (): Promise<EducationCategory[]> => {
  try {
    const client = await EdexClientJava.getClient();
    const response = await client.educationControllerEducationCategoryList();
    return response.data.sort(({ position: a }, { position: b }) => a - b);
  } catch (err) {
    throw new Error(err);
  }
};

export function useGetEducationCategories(): SWRHook<EducationCategory[]> {
  const { data, error, mutate } = useSWR(
    [SwrKeys.useGetEducationCategories],
    getEducationCategories
  );
  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
}
