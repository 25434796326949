import type { FC } from 'react';
import type { Theme } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
  white?: boolean;
}

const LogoRoot = experimentalStyled('svg')({});

const Logo: FC<LogoProps> = (props) => {
  const { white } = props;
  return (
    <LogoRoot version="1.1" xmlSpace="preserve" viewBox="0 0 1500 750" {...props}>
      <g>
        <path
          d="M687.5 267.5v-60c0-2.8-2.2-5-5-5h-210c-2.8 0-5 2.2-5 5v60c0 2.8 2.2 5 5 5h210c2.8 0 5-2.2 5-5"
          style={{
            fill: white ? '#ffffff' : '#3769ff',
          }}
        />
        <path
          style={{
            fill: white ? '#ffffff' : '#000000',
          }}
          d="M447.5 547.5h-45c-17.7 0-34.7-6.7-47.7-18.8-12.9-12-20.8-28.3-22.1-45.8-.1-1.4.4-2.8 1.3-3.8s2.3-1.6 3.7-1.6h109.8c2.8 0 5 2.2 5 5v60c0 2.8-2.2 5-5 5m5-185c0-60.8-49.2-110-110-110h-105c-2.8 0-5 2.2-5 5v285c0 2.8 2.2 5 5 5h60c2.8 0 5-2.2 5-5v-220h40c22.1 0 40 17.9 40 40s-17.9 40-40 40h-5c-2.8 0-5 2.2-5 5v60c0 2.8 2.2 5 5 5h5c60.8 0 110-49.3 110-110m85 75c0-22.1 17.9-40 40-40s40 17.9 40 40-17.9 40-40 40-40-17.9-40-40m40 110c60.8 0 110-49.2 110-110s-49.2-110-110-110-110 49.2-110 110 49.2 110 110 110m350-110c0-60.8-49.2-110-110-110s-110 49.2-110 110v105c0 2.8 2.2 5 5 5h60c2.8 0 5-2.2 5-5v-105c0-22.1 17.9-40 40-40s40 17.9 40 40v105c0 2.8 2.2 5 5 5h60c2.8 0 5-2.2 5-5v-105zm95 105v-210c0-2.8-2.2-5-5-5h-60c-2.8 0-5 2.2-5 5v210c0 2.8 2.2 5 5 5h60c2.8 0 5-2.2 5-5m245-105c0-60.8-49.2-110-110-110s-110 49.2-110 110v105c0 2.8 2.2 5 5 5h60c2.8 0 5-2.2 5-5v-105c0-22.1 17.9-40 40-40s40 17.9 40 40v105c0 2.8 2.2 5 5 5h60c2.8 0 5-2.2 5-5v-105z"
        />
      </g>
    </LogoRoot>
  );
};

export default Logo;
