import { useTheme } from '@emotion/react';
import { Box, Button } from '@mui/material';
import { FileUploadModal } from 'components/dashboard/FileUploadModal';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { createOpts } from 'snacks';
import { CreateEducationEpisodeForm } from './CreateEducationEpisodeForm';
import { CreateEducationFileEntry } from './CreateEducationFileEntry';

export interface CreateEducationDocumentTableEntry {
  source: string | Blob;
  type: 'VIDEO' | 'SLIDES';
}
export interface CreateEducationDocument {
  id: number;
  title: string;
  position: number;
  tableEntries: CreateEducationDocumentTableEntry[];
}

export interface CreateEducationDocumentsTableProps {
  uploadedDocuments: CreateEducationDocument[];
  onFormValueChange: (documentIndex, values: { title: string; position: number }) => void;
  onDocumentUpload: (
    documentIndex: number,
    fileTypeIndex: number,
    file: Blob,
    docType: 'VIDEO' | 'SLIDES'
  ) => void;
  onDocumentDiscard: (
    documentIndex: number,
    fileTypeIndex: number,
    docType: 'VIDEO' | 'SLIDES'
  ) => void;
  onEpisodeDeletion: (documentIndex: number) => void;
  onSaveEpisode: (documentIndex: number) => void;
  onEpisodeValidityChange: (documentIndex: number, isValid: boolean) => void;
}

export const CreateEducationDocumentsTable: FC<CreateEducationDocumentsTableProps> = (
  props: CreateEducationDocumentsTableProps
): JSX.Element => {
  const {
    uploadedDocuments,
    onFormValueChange,
    onDocumentUpload,
    onDocumentDiscard,
    onEpisodeDeletion,
    onSaveEpisode,
    onEpisodeValidityChange,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const onUploadCampaignDocument = (documentIndex, fileTypeIndex) => {
    showModal(FileUploadModal, {
      withDescription: false,
      withSigningCheckbox: false,
      pickOnly: true,
      acceptUploadFileType:
        uploadedDocuments[documentIndex].tableEntries[fileTypeIndex].type === 'VIDEO'
          ? '.avi,.mp4,.mpeg,.webm'
          : '.pdf,.xls,.xlsx,.xlsxm',
      onPickFile: (file) => {
        if (!file) {
          return;
        }
        const type = uploadedDocuments[documentIndex].tableEntries[fileTypeIndex].type;
        const maxSize = type === 'VIDEO' ? 250 : 5;
        const fileSizeInMb = file.size / 1024 / 1024;
        if (fileSizeInMb > maxSize) {
          const msgPath =
            type === 'VIDEO' ? 'educationMaterialVideoSize' : 'educationMaterialPdfMaterial';
          enqueueSnackbar(t(`education.createMaterial.${msgPath}`), createOpts('error') as any);
          return;
        }
        if (file) {
          onDocumentUpload(
            documentIndex,
            fileTypeIndex,
            file,
            uploadedDocuments[documentIndex].tableEntries[fileTypeIndex].type
          );
        }
      },
    });
  };

  const onDiscardCampaignDocument = (documentIndex, fileTypeIndex) => {
    if (onDocumentDiscard) {
      onDocumentDiscard(
        documentIndex,
        fileTypeIndex,
        uploadedDocuments[documentIndex].tableEntries[fileTypeIndex].type
      );
    }
  };
  const onDeleteEpisode = (documentIndex) => {
    if (onEpisodeDeletion) {
      onEpisodeDeletion(documentIndex);
    }
  };
  const onEpisodeFormValueChange = (documentIndex, values) => {
    if (onFormValueChange) {
      onFormValueChange(documentIndex, values);
    }
  };
  const onEducationEpisodeFormValidityChange = (documentIndex, isValid) => {
    if (onEpisodeValidityChange) {
      onEpisodeValidityChange(documentIndex, isValid);
    }
  };
  const onEpisodeSave = (documentIndex) => {
    if (onSaveEpisode) {
      onSaveEpisode(documentIndex);
    }
  };

  return (
    <Box>
      {uploadedDocuments.map((uploadedDocument: CreateEducationDocument, index: number) => {
        return (
          <Box
            key={index}
            sx={{
              mb: '16px',
              p: '16px',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              borderRadius: '12px',
            }}
          >
            <CreateEducationEpisodeForm
              title={uploadedDocument.title}
              position={uploadedDocument.position}
              onChange={(value) => onEpisodeFormValueChange(index, value)}
              onValidityChange={(isValid) => onEducationEpisodeFormValidityChange(index, isValid)}
            />
            {uploadedDocument.tableEntries.map((section, innerIndex) => (
              <CreateEducationFileEntry
                key={`${index}-${innerIndex}`}
                educationDocument={section}
                handleUpload={() => onUploadCampaignDocument(index, innerIndex)}
                handleDiscard={() => onDiscardCampaignDocument(index, innerIndex)}
              ></CreateEducationFileEntry>
            ))}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                color="primary"
                sx={{ ml: 'auto', width: '100%' }}
                variant="outlined"
                onClick={() => onDeleteEpisode(index)}
              >
                {t('generic.table.delete')}
              </Button>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                fullWidth={false}
                onClick={() => onEpisodeSave(index)}
              >
                {t('education.createMaterial.save')}
              </Button>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
