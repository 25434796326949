import { useEffect, useState } from 'react';
import { Box, Typography, Dialog, DialogProps, Checkbox, FormControlLabel } from '@mui/material';

import { FileDropzone } from 'components/generic/FileDropzone';
import { UploadDocument, AugmentedDocument, FileTypeUnion } from 'types/document';
import { blobToFile, dataURIToBlob, isImageFile, resizeFile } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { RoninInput } from 'ui/ronin/RoninInput';
import { useSnackbar } from 'notistack';
import { UploadCustomDocumentError } from 'snacks';

type Props<ModalData extends AugmentedDocument> = DialogProps & {
  modalData?: ModalData;
  uploadDocument?: UploadDocument;
  relationName?: string;
  onUploadSuccess?: () => void;
  // If provided as true then we show a field where the user needs to insert the name of the document.
  withDescription?: boolean;
  // If provided as true then show a checkbox that will mark if the new document needs a signature
  withSigningCheckbox?: boolean;
  // Pick only determines the upload will not happen, but the calling component will handle the upload.
  // The component will only handle the picking of the document
  pickOnly?: boolean;
  // When pickOnly is set to true, this callback will be used to send back the file to the calling component
  onPickFile?: (file, description?) => void;
  acceptUploadFileType?: string;
  maxSize?: number;
  maxSizeErrorTranslationKey?: string;
};

const getImageResize = (imageFileType: FileTypeUnion): [number, number] => {
  switch (imageFileType) {
    case 'COVER':
      return [1024, 1024];
    case 'LOGO':
      return [160, 160];
    default:
      return [300, 300];
  }
};

export const FileUploadModal = <ModalData extends AugmentedDocument>(
  props: Props<ModalData>
): JSX.Element => {
  const {
    modalData = {} as ModalData,
    pickOnly,
    uploadDocument = () => {},
    onUploadSuccess = () => {},
    onPickFile,
    relationName,
    withDescription = false,
    withSigningCheckbox = false,
    acceptUploadFileType,
    maxSize,
    maxSizeErrorTranslationKey,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<File>(null);
  const [description, setDescription] = useState('');
  const [uploading, setUploading] = useState(false);
  const [signingBoxValue, setSigningBoxValue] = useState(false);

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [img, setImg] = useState<string>('');
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (uploadSuccess) {
        handleClose();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [uploadSuccess]);

  const handleClose = () => {
    if (pickOnly) {
      if (onPickFile) {
        onPickFile(null);
      }
    }
    setOpen(false);
  };

  const onDrop = async (droppedFiles: File[]) => {
    if (droppedFiles.length && isImageFile(droppedFiles[0])) {
      const fileName = droppedFiles[0].name;
      const imageUri = await resizeFile(droppedFiles[0], ...getImageResize(modalData.type));
      setImg(imageUri);
      const blob = dataURIToBlob(imageUri);
      const fileToUpload = blobToFile(blob, fileName, droppedFiles[0].type);
      setFile(fileToUpload);
    } else {
      setImg('');
      setFile(droppedFiles[0]);
    }
  };

  const onUploadOrPick = async () => {
    if (pickOnly) {
      if (onPickFile) {
        if (withDescription && !description.trim()) {
          enqueueSnackbar(...UploadCustomDocumentError(t));
          return;
        }
        setOpen(false);
        onPickFile(file, description);
      }
      return;
    }
    if (withDescription && !description.trim()) {
      enqueueSnackbar(...UploadCustomDocumentError(t));
      return;
    }
    try {
      setUploadSuccess(false);
      setUploading(true);
      await uploadDocument(
        modalData[relationName],
        modalData.type,
        file,
        description,
        signingBoxValue
      );
      onUploadSuccess();
      setUploading(false);
      setUploadSuccess(true);
    } catch (e) {
      setUploadSuccess(false);
      throw new Error(e);
    }
  };

  const onRemove = () => {
    setFile(null);
  };
  const handleSigningBoxChange = (event) => {
    setSigningBoxValue(event.target.checked);
  };
  // if (uploadSuccess) {
  //   setTimeout(() => {
  //     handleClose();
  //   }, 1000);
  // }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
        {withDescription ? (
          <Box sx={{ pl: '16px', pt: '16px', pr: '16px' }}>
            <RoninInput
              fullWidth={true}
              placeholder={t('actions.enterName')}
              name="customizableInput"
              onChange={($event) => setDescription($event.target.value)}
            />
          </Box>
        ) : (
          <></>
        )}
        {withSigningCheckbox ? (
          <Box sx={{ pl: '16px', pt: '16px', pb: '16px' }}>
            <FormControlLabel
              label={t('generic.fileUploadModal.availableForSignature')}
              control={
                <Checkbox
                  checked={signingBoxValue}
                  color="primary"
                  onChange={handleSigningBoxChange}
                />
              }
            />
          </Box>
        ) : (
          <></>
        )}
        {uploadSuccess ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              p: 2,
            }}
          >
            <Typography color="textPrimary" variant="h1">
              {t('generic.fileUploadModal.uploadSuccess')}
            </Typography>
            <Typography
              sx={{ color: 'primary.main', cursor: 'pointer' }}
              variant="h1"
              onClick={handleClose}
            >
              {t('generic.fileUploadModal.return')}
            </Typography>
          </Box>
        ) : (
          <FileDropzone
            propagateDrop={onDrop}
            onUpload={onUploadOrPick}
            maxSize={maxSize}
            maxSizeErrorTranslationKey={maxSizeErrorTranslationKey}
            onRemove={onRemove}
            uploading={uploading}
            pickOnly={pickOnly}
            file={file}
            imageUri={img}
            maxFiles={1}
            accept={modalData.acceptUploadFileType || acceptUploadFileType}
          />
        )}
      </Box>
    </Dialog>
  );
};
