import { UserRole } from 'types/user';

type Visibility = Array<UserRole | 'ANY'>;

interface SectionItem {
  title: string;
  path: string;
  visibility?: Visibility;
  logoUrl?: string;
}
interface Section {
  title: string;
  items: SectionItem[];
  visibility: Visibility;
}

interface UserRoleItems {
  ADMIN: UserRoleMenuItems;
  INVESTOR: UserRoleMenuItems;
  ISSUER: UserRoleMenuItems;
}

interface UserRoleMenuItems {
  primary: SectionItem;
  infoText: string;
  secondary: SectionItem[];
}

const items: UserRoleItems = {
  ADMIN: {
    primary: {
      title: 'menuItems.overview',
      path: '/dashboard',
    },
    infoText: 'menuItems.adminInfoText',
    secondary: [
      {
        title: 'menuItems.issuers',
        path: '/dashboard/issuers-workspace',
      },
      {
        title: 'menuItems.investors',
        path: '/dashboard/investors-workspace',
      },
      {
        title: 'menuItems.campaigns',
        path: '/dashboard/campaigns-workspace',
      },
      {
        title: 'menuItems.investments',
        path: '/dashboard/investments-workspace',
      },
      {
        title: 'menuItems.education',
        path: '/dashboard/education-workspace',
      },
    ],
  },
  INVESTOR: {
    primary: {
      title: 'menuItems.myProfile',
      path: '/dashboard/investor-profile',
      logoUrl: '/dashboard',
    },
    infoText: 'menuItems.investorInfoText',
    secondary: [
      {
        title: 'menuItems.campaigns',
        path: '/dashboard',
      },
      {
        title: 'menuItems.myInvestments',
        path: '/dashboard/investor-investments',
      },
      {
        title: 'menuItems.education',
        path: '/dashboard/investor-education',
      },
    ],
  },
  ISSUER: {
    primary: {
      title: 'menuItems.overview',
      path: '/dashboard',
    },
    infoText: 'menuItems.issuerInfoText',
    secondary: [
      {
        title: 'menuItems.companyProfile',
        path: '/dashboard/company-profile',
      },
      {
        title: 'menuItems.startCampaign',
        path: '/dashboard/crowdfunding-campaign',
      },
    ],
  },
};

export const getMenuItems = (userRole: UserRole, hasEducation): UserRoleMenuItems => {
  const userItems = items[userRole];
  if (userItems) {
    userItems.secondary = userItems.secondary.filter((secondaryItem) => {
      if (secondaryItem.path.includes('investor-education')) {
        return hasEducation;
      }
      return true;
    });
    return userItems;
  }
  return {} as any;
};

const sections: Section[] = [
  {
    title: 'menuItems.general',
    visibility: ['ANY'],
    items: [
      {
        title: 'menuItems.overview',
        path: '/dashboard',
        visibility: ['ANY'],
      },
    ],
  },
  {
    title: 'menuItems.workspace',
    visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
    items: [
      {
        title: 'menuItems.issuers',
        path: '/dashboard/issuers-workspace',
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      {
        title: 'menuItems.investors',
        path: '/dashboard/investors-workspace',
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      {
        title: 'menuItems.campaigns',
        path: '/dashboard/campaigns-workspace',
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      {
        title: 'menuItems.investments',
        path: '/dashboard/investments-workspace',
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      {
        title: 'menuItems.education',
        path: '/dashboard/education-workspace',
        visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      },
      // {
      //   title: 'menuItems.newsFeed',
      //   path: '/dashboard/news-feed-workspace',
      //   visibility: ['ADMIN', 'ACCOUNT_MANAGER'],
      // },
    ],
  },
  {
    title: 'menuItems.crowdfunding',
    visibility: ['ISSUER'],
    items: [
      {
        title: 'menuItems.companyProfile',
        path: '/dashboard/company-profile',
        visibility: ['ISSUER'],
      },
      {
        title: 'menuItems.startCampaign',
        path: '/dashboard/crowdfunding-campaign',
        visibility: ['ISSUER'],
      },
      // {
      //   title: 'menuItems.newsFeed',
      //   path: '/dashboard/news-feed',
      //   visibility: ['ISSUER'],
      // },
    ],
  },
  {
    title: 'menuItems.investing',
    visibility: ['INVESTOR'],
    items: [
      {
        title: 'menuItems.myProfile',
        path: '/dashboard/investor-profile',
        visibility: ['INVESTOR'],
      },
      {
        title: 'menuItems.myInvestments',
        path: '/dashboard/investor-investments',
        visibility: ['INVESTOR'],
      },
      {
        title: 'menuItems.education',
        path: '/dashboard/investor-education',
        visibility: ['INVESTOR'],
      },
      // {
      //   title: 'menuItems.newsFeed',
      //   path: '/dashboard/news-feed',
      //   visibility: ['INVESTOR'],
      // },
    ],
  },
];

export const getSections = (userRole: UserRole, hasEducation): Section[] => {
  const filterByRole = (section: Section | SectionItem) =>
    section.visibility.includes(userRole) || section.visibility.includes('ANY');
  const filteredSections = sections.map((section) => {
    return {
      ...section,
      items: section.items.filter(filterByRole).filter((item) => {
        if (item.path.includes('dashboard/investor-education')) {
          return hasEducation || ['ADMIN', 'ACCOUNT_MANAGER'].includes(userRole);
        }
        return true;
      }),
    };
  });
  return filteredSections.filter(filterByRole);
};
