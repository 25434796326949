import { useEffect, useState } from 'react';
import { EdexClientJava } from 'lib/axios';
import KeycloakUserService from 'utils/KeycloakUserService';
import useCurrentLanguage from './useCurrentLanguage';
import i18n from 'i18n/i18n';

export const useInitAppDependencies = (setCurrentUser): { initialized: boolean } => {
  const [edexClientInitialized, setEdexClientInitialized] = useState(false);
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useCurrentLanguage();
  useEffect(() => {
    const initDependencies = async () => {
      await EdexClientJava.setClient();
      setEdexClientInitialized(true);
      KeycloakUserService.initKeycloak(
        (_loggedIn, kcInstance) => {
          setKeycloakInitialized(true);
          if (kcInstance?.tokenParsed?.locale) {
            i18n.changeLanguage(kcInstance?.tokenParsed?.locale);
          }
        },
        setCurrentUser,
        currentLanguage
      );
    };
    initDependencies();
  }, []);
  return { initialized: edexClientInitialized && keycloakInitialized };
};
