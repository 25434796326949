export const RegisterSuccessSnack = {
  message: 'toastMessages.registerSuccessSnack',
  opts: {
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'top',
    },
    variant: 'info',
  },
};

const anchorOrigin = {
  horizontal: 'right',
  vertical: 'top',
};

export const getTranslatedSnack = (t, snack): SnackTuple[] => {
  return [t(snack[0]), snack[1]];
};
type SnackTuple = [string, unknown];

export const createOpts = (variant) => ({ anchorOrigin, variant });

export const PrioritySubscribeFail = (t): SnackTuple => [
  t('toastMessages.prioritySubscribeFail'),
  createOpts('error'),
];
export const PrioritySubscribeInvalidFormFail = (t): SnackTuple => [
  t('toastMessages.prioritySubscribeInvalidFormFail'),
  createOpts('error'),
];

export const WalletQrCodeSuccess = (t): SnackTuple => [
  t('toastMessages.walletQrCodeSuccess'),
  createOpts('success'),
];
export const WalletQrCodeFail = (t): SnackTuple => [
  t('toastMessages.walletQrCodeFail'),
  createOpts('error'),
];
export const WalletQrCodeRemoveSuccess = (t): SnackTuple => [
  t('toastMessages.walletQrCodeRemoveSuccess'),
  createOpts('success'),
];
export const InvestmentsRemoveSuccess = (t): SnackTuple => [
  t('toastMessages.investmentsRemoveSuccess'),
  createOpts('success'),
];
export const InvestmentsRemoveFail = (t): SnackTuple => [
  t('toastMessages.investmentsRemoveFail'),
  createOpts('error'),
];
export const UploadCustomDocumentError = (t): SnackTuple => [
  t('toastMessages.uploadCustomDocumentError'),
  createOpts('error'),
];
export const InvestmentsRemoveFailOnlyNew = (t): SnackTuple => [
  t('toastMessages.investmentsRemoveFailOnlyNew'),
  createOpts('error'),
];
export const InvestmentTicketUpdateSuccess = (t): SnackTuple => [
  t('toastMessages.investmentTicketUpdateSuccess'),
  createOpts('success'),
];
export const InvestmentCostDataUpdateSuccess = (t): SnackTuple => [
  t('toastMessages.investmentCostUpdateSuccess'),
  createOpts('success'),
];
export const getInvestmentTicketUpdateFail = (reason, t) => [
  reason || t('toastMessages.getInvestmentTicketUpdateFail'),
  createOpts('error'),
];
export const WalletQrCodeRemoveFail = (t): SnackTuple => [
  t('toastMessages.walletQrCodeRemoveFail'),
  createOpts('error'),
];

export const CompanyInfoSaveSuccess = (t): SnackTuple => [
  t('toastMessages.companyInfoSaveSuccess'),
  createOpts('success'),
];
export const CompanyInfoSaveFail = (t): SnackTuple => [
  t('toastMessages.companyInfoSaveFail'),
  createOpts('error'),
];
export const CompanyMemberSaveSuccess = (t): SnackTuple => [
  t('toastMessages.companyMemberSaveSuccess'),
  createOpts('success'),
];
export const CompanyMemberSaveFail = (t): SnackTuple => [
  t('toastMessages.companyMemberSaveFail'),
  createOpts('error'),
];
export const CompanyMemberDeleteSuccess = (t): SnackTuple => [
  t('toastMessages.companyMemberDeleteSuccess'),
  createOpts('success'),
];
export const CompanyMemberDeleteFail = (t): SnackTuple => [
  t('toastMessages.companyMemberDeleteFail'),
  createOpts('error'),
];

export const InvestorInformationUpdateSuccess = (t): SnackTuple => [
  t('toastMessages.investorInformationUpdateSuccess'),
  createOpts('success'),
];
export const InvestorInformationUpdateFail = (t): SnackTuple => [
  t('toastMessages.investorInformationUpdateFail'),
  createOpts('error'),
];

export const CampaignInfoUpdateSuccess = (t): SnackTuple => [
  t('toastMessages.campaignInfoUpdateSuccess'),
  createOpts('success'),
];
export const CampaignInfoUpdateFail = (t): SnackTuple => [
  t('toastMessages.campaignInfoUpdateFail'),
  createOpts('error'),
];
export const AdminDocumentUploadAndSignCancel = (t): SnackTuple => [
  t('toastMessages.adminDocumentUploadAndSignCancel'),
  createOpts('error'),
];
export const AdminDocumentSignStartFailed = (t): SnackTuple => [
  t('toastMessages.adminDocumentSignStartFailed'),
  createOpts('error'),
];

// Admin document signing ---- shown when returning from InfoCert signing
export const AdminDocumentSignSuccess = (t): SnackTuple => [
  t('toastMessages.adminDocumentSignSuccess'),
  createOpts('success'),
];
export const AdminDocumentSignError = (t): SnackTuple => [
  t('toastMessages.adminDocumentSignError'),
  createOpts('error'),
];
// END Admin document signing ---- shown when returning from InfoCert signing

export const CampaignFinalizeSuccess = (t): SnackTuple => [
  t('toastMessages.campaignFinalizeSuccess'),
  createOpts('success'),
];
export const CampaignFinalizeFail = (t): SnackTuple => [
  t('toastMessages.campaignFinalizeFail'),
  createOpts('error'),
];

export const InvestmentSaveSuccess = (t): SnackTuple => [
  t('toastMessages.investmentSaveSuccess'),
  createOpts('success'),
];
export const InvestmentSaveFail = (t): SnackTuple => [
  t('toastMessages.investmentSaveFail'),
  createOpts('error'),
];

export const TokenizationStartSuccess = (t): SnackTuple => [
  t('toastMessages.tokenizationStartSuccess'),
  createOpts('success'),
];

export const TokenizationStartError = (t): SnackTuple => [
  t('toastMessages.tokenizationStartError'),
  createOpts('error'),
];

export const TokenizationFinishSuccess = (t): SnackTuple => [
  t('toastMessages.tokenizationFinishSuccess'),
  createOpts('success'),
];

export const TokenizationFinishFail = (t): SnackTuple => [
  t('toastMessages.tokenizationFinishFail'),
  createOpts('error'),
];

export const NewsFeedCreateSuccess = (t): SnackTuple => [
  t('toastMessages.newsFeedCreateSuccess'),
  createOpts('success'),
];

export const NewsFeedCreateError = (t): SnackTuple => [
  t('toastMessages.newsFeedCreateError'),
  createOpts('error'),
];
export const NewsFeedUpdateSuccess = (t): SnackTuple => [
  t('toastMessages.newsFeedUpdateSuccess'),
  createOpts('success'),
];

export const NewsFeedUpdateError = (t): SnackTuple => [
  t('toastMessages.newsFeedUpdateError'),
  createOpts('error'),
];

export const InvestorProfileQuestionnaireSubmitSuccess = (t): SnackTuple => [
  t('toastMessages.investorProfileQuestionnaireSubmitSuccess'),
  createOpts('success'),
];
export const InvestorProfileQuestionnaireSubmitError = (t): SnackTuple => [
  t('toastMessages.investorProfileQuestionnaireSubmitError'),
  createOpts('error'),
];

export const InvestorProfileTestSubmitSuccess = (t): SnackTuple => [
  t('toastMessages.investorProfileTestSubmitSuccess'),
  createOpts('success'),
];
export const InvestorProfileTestSubmitError = (t): SnackTuple => [
  t('toastMessages.investorProfileTestSubmitError'),
  createOpts('error'),
];

export const InvestorProfileWarningApproveSuccess = (t): SnackTuple => [
  t('toastMessages.investorProfileWarningApproveSuccess'),
  createOpts('success'),
];
export const InvestorProfileWarningApproveError = (t): SnackTuple => [
  t('toastMessages.investorProfileWarningApproveError'),
  createOpts('error'),
];

export const InvestorProfileSimulationSubmitSuccess = (t): SnackTuple => [
  t('toastMessages.investorProfileSimulationSubmitSuccess'),
  createOpts('success'),
];
export const InvestorProfileSimulationSubmitError = (t): SnackTuple => [
  t('toastMessages.investorProfileSimulationSubmitError'),
  createOpts('error'),
];
