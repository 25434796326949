import { EdexClientJava } from '../lib/axios';
import { Components } from '../lib/EdexClientJava';

import type { CompanyMember } from 'types/company';
import { EducationCategory } from 'types/education';

export const addEducationCategory = async ({
  title,
  position,
  description,
  visible,
  coverImage,
}: {
  title: string;
  position: number;
  description: string;
  visible: boolean;
  coverImage: Blob;
}): Promise<EducationCategory> => {
  try {
    const client = await EdexClientJava.getClient();
    const formDataPayload = new FormData();
    formDataPayload.append('coverImage', coverImage);
    const response = await client.educationControllerAddEducationCategory(
      {
        title,
        position,
        description,
        visible,
      },
      formDataPayload,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    const member = response.data as EducationCategory;
    return member;
  } catch (err) {
    throw new Error(err);
  }
};
