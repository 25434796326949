export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const SiteName = 'RONIN';

export enum ApiUrl {
  local = 'http://localhost:3000',
  development = 'https://app-dev.weronin.com/api/',
  staging = 'https://app-staging.weronin.com/api/',
  production = 'https://app.weronin.com/api/',
}
export enum ApiUrlJava {
  local = 'http://localhost:3000',
  development = 'https://app-dev.weronin.com/api/',
  staging = 'https://app-staging.weronin.com/api/',
  production = 'https://app.weronin.com/api/',
}

export enum Routes {
  Dashboard = '/dashboard',
  FinishRegistration = '/authentication/finish-register',
  InvestmentWizzard = '/dashboard/investment-wizard',
  InvestorCampaignDetails = '/dashboard/investor-campaign',
  IssuerProfile = '/dashboard/company-profile',
  InvestorProfile = '/dashboard/investor-profile',
  AddEducationMaterial = '/dashboard/create-education-material',
}

export const GTM_EVENTS = {
  PAGE_VIEW: 'PAGE_VIEW',
  LANGUAGE_DROPDOWN_CLICK: 'RONIN_LANGUAGE_DROPDOWN_CLICK',
  LANGUAGE_CHANGE_CLICK: 'RONIN_LANGUAGE_CHANGE_CLICK',
  ACCOUNT_DROPDOWN_CLICK: 'RONIN_ACCOUNT_DROPDOWN_CLICK',
  ACCOUNT_SETTINGS_CLICK: 'RONIN_ACCOUNT_SETTINGS_CLICK',
  LOGOUT_CLICK: 'RONIN_LOGOUT_CLICK',
  LOGIN_CLICK: 'RONIN_LOGIN_CLICK',
  REGISTER_CLICK: 'RONIN_REGISTER_CLICK',
  CREATE_NEW_ACCOUNT_CLICK: 'RONIN_CREATE_NEW_ACCOUNT_CLICK',
  FORGOT_PASSWORD_CLICK: 'RONIN_FORGOT_PASSWORD_CLICK',
  RECOVER_PASSWORD_CLICK: 'RONIN_RECOVER_PASSWORD_CLICK',
  HAVING_AN_ACCOUNT_CLICK: 'RONIN_HAVING_AN_ACCOUNT_CLICK',
  CODE_SUBMIT_CLICK: 'RONIN_CODE_SUBMIT_CLICK',
  SETTINGS_CLICK: 'RONIN_SETTINGS_CLICK',
  SETTINGS_GENERAL_TAB_CLICK: 'RONIN_SETTINGS_GENERAL_TAB_CLICK',
  SETTINGS_GENERAL_SAVE_CHANGES_CLICK: 'RONIN_SETTINGS_GENERAL_SAVE_CHANGES_CLICK',
  SETTINGS_SECURITY_TAB_CLICK: 'RONIN_SETTINGS_SECURITY_TAB_CLICK',
  SETTINGS_CHANGE_PASSWORD_CLICK: 'RONIN_SETTINGS_CHANGE_PASSWORD_CLICK',
  SETTINGS_SECURITY_GENERATE_QR_CODE_CLICK: 'RONIN_SETTINGS_SECURITY_GENERATE_QR_CODE_CLICK',
  STATUS_LISTED_CLICK: 'RONIN_STATUS_LISTED_CLICK',
  STATUS_UNLIST_CLICK: 'RONIN_STATUS_UNLIST_CLICK',
  STATUS_AGREEMENT_SENT_CLICK: 'RONIN_STATUS_AGREEMENT_SENT_CLICK',
  STATUS_NEW_CLICK: 'RONIN_STATUS_NEW_CLICK',
  STATUS_VERIFIED_CLICK: 'RONIN_STATUS_VERIFIED_CLICK',
  STATUS_KYC_KYB_AML_CLICK: 'RONIN_STATUS_KYC_KYB_AML_CLICK',
  STATUS_ACCEPT_CLICK: 'RONIN_STATUS_ACCEPT_CLICK',
  STATUS_EJECT_CLICK: 'RONIN_STATUS_REJECT_CLICK',
  STATUS_AGREEMENT_SIGNED_CLICK: 'RONIN_STATUS_AGREEMENT_SIGNED_CLICK',
  STATUS_SPV_SENT_CLICK: 'RONIN_STATUS_SPV_SENT_CLICK',
  STATUS_SPV_SIGNED_CLICK: 'RONIN_STATUS_SPV_SIGNED_CLICK',
  STATUS_FUNDS_REQUEST_CLICK: 'RONIN_STATUS_FUNDS_REQUEST_CLICK',
  STATUS_FUNDS_RECEIVED_CLICK: 'RONIN_STATUS_FUNDS_RECEIVED_CLICK',
  STATUS_TOKENIZATION_CLICK: 'RONIN_STATUS_TOKENIZATION_CLICK',
  STATUS_DONE_CLICK: 'RONIN_STATUS_DONE_CLICK',
  INVEST_CLICK: 'RONIN_INVEST_CLICK',
  DOCUMENT_DISCARD: 'RONIN_DOCUMENT_DISCARD',
  DOCUMENT_SIGN: 'RONIN_DOCUMENT_SIGN',
  DOCUMENT_UPLOAD: 'RONIN_DOCUMENT_UPLOAD',
  DOCUMENT_VIEW: 'RONIN_DOCUMENT_VIEW',
  DOCUMENT_DOWNLOAD: 'RONIN_DOCUMENT_DOWNLOAD',
  DELETE_CLICK: 'RONIN_DELETE_CLICK',
  DETAILS_CLICK: 'RONIN_DETAILS_CLICK',
  REMOVE_CLICK: 'RONIN_REMOVE_CLICK',
  ADD_MORE_CLICK: 'RONIN_ADD_MORE_CLICK',
  ISSUER_CONTACT_DETAILS_TAB_CLICK: 'RONIN_ISSUER_CONTACT_DETAILS_TAB_CLICK',
  ISSUER_COMPANY_TAB_CLICK: 'RONIN_ISSUER_COMPANY_TAB_CLICK',
  ISSUER_LEGAL_REPRESENTATIVES_TAB_CLICK: 'RONIN_ISSUER_LEGAL_REPRESENTATIVES_TAB_CLICK',
  ISSUER_SHAREHOLDERS_TAB_CLICK: 'RONIN_ISSUER_SHAREHOLDERS_TAB_CLICK',
  ISSUER_UPDATE_CONTACT_DETAILS_CLICK: 'RONIN_ISSUER_UPDATE_CONTACT_DETAILS_CLICK',
  ISSUER_UPDATE_COMPANY_CLICK: 'RONIN_ISSUER_UPDATE_COMPANY_CLICK',
  ISSUER_SAVE_COMPANY_CLICK: 'RONIN_ISSUER_SAVE_COMPANY_CLICK',
  ISSUER_SAVE_LEGAL_REPRESENTATIVES_CLICK: 'RONIN_ISSUER_SAVE_LEGAL_REPRESENTATIVES_CLICK',
  ISSUER_UPDATE_LEGAL_REPRESENTATIVES_CLICK: 'RONIN_ISSUER_UPDATE_LEGAL_REPRESENTATIVES_CLICK',
  ISSUER_ADD_LEGAL_REPRESENTATIVES_CLICK: 'RONIN_ISSUER_ADD_LEGAL_REPRESENTATIVES_CLICK',
  ISSUER_REMOVE_LEGAL_REPRESENTATIVES_CLICK: 'RONIN_ISSUER_REMOVE_LEGAL_REPRESENTATIVES_CLICK',
  ISSUER_SAVE_SHAREHOLDERS_CLICK: 'RONIN_ISSUER_SAVE_SHAREHOLDERS_CLICK',
  ISSUER_UPDATE_SHAREHOLDERS_CLICK: 'RONIN_ISSUER_UPDATE_SHAREHOLDERS_CLICK',
  ISSUER_ADD_SHAREHOLDERS_CLICK: 'RONIN_ISSUER_ADD_SHAREHOLDERS_CLICK',
  ISSUER_REMOVE_SHAREHOLDERS_CLICK: 'RONIN_ISSUER_REMOVE_SHAREHOLDERS_CLICK',
  INVESTOR_GENERAL_INFORMATION_TAB_CLICK: 'RONIN_INVESTOR_GENERAL_INFORMATION_TAB_CLICK',
  INVESTOR_UPDATE_GENERAL_INFORMATION_CLICK: 'RONIN_INVESTOR_UPDATE_GENERAL_INFORMATION_CLICK',
  INVESTOR_SAVE_GENERAL_INFORMATION_CLICK: 'RONIN_INVESTOR_SAVE_GENERAL_INFORMATION_CLICK',
  INVESTOR_PROFILE_TAB_CLICK: 'RONIN_INVESTOR_PROFILE_TAB_CLICK',
  INVESTOR_WALLET_TAB_CLICK: 'RONIN_INVESTOR_WALLET_TAB_CLICK',
  INVESTOR_WALLET_CONNECT_CLICK: 'RONIN_INVESTOR_WALLET_CONNECT_CLICK',
  INVESTOR_WALLET_DISCONNECT_CLICK: 'RONIN_INVESTOR_WALLET_DISCONNECT_CLICK',
  INVESTOR_INVESTMENTS_TAB_CLICK: 'RONIN_INVESTOR_INVESTMENTS_TAB_CLICK',
  INVESTOR_ONGOING_CAMPAIGNS_DETAILS_CLICK: 'RONIN_INVESTOR_ONGOING_CAMPAIGNS_DETAILS_CLICK',
  CAMPAIGN_DETAILS_UPDATE_CLICK: 'RONIN_CAMPAIGN_DETAILS_UPDATE_CLICK',
  CAMPAIGN_DETAILS_SAVE_CLICK: 'RONIN_CAMPAIGN_DETAILS_SAVE_CLICK',
  CAMPAIGN_DESCRIPTION_TAB_CLICK: 'RONIN_CAMPAIGN_DESCRIPTION_TAB_CLICK',
  CAMPAIGN_DESCRIPTION_SAVE_CLICK: 'RONIN_CAMPAIGN_DESCRIPTION_SAVE_CLICK',
  CAMPAIGN_TEAM_TAB_CLICK: 'RONIN_CAMPAIGN_TEAM_TAB_CLICK',
  CAMPAIGN_TEAM_UPDATE_CLICK: 'RONIN_CAMPAIGN_TEAM_UPDATE_CLICK',
  CAMPAIGN_TEAM_SAVE_CLICK: 'RONIN_CAMPAIGN_TEAM_SAVE_CLICK',
  CAMPAIGN_TEAM_ADD_CLICK: 'RONIN_CAMPAIGN_TEAM_ADD_CLICK',
  CAMPAIGN_TEAM_REMOVE_CLICK: 'RONIN_CAMPAIGN_TEAM_REMOVE_CLICK',
  CAMPAIGN_YOUTUBE_TAB_CLICK: 'RONIN_INVESTMENT_YOUTUBE_TAB_CLICK',
  CAMPAIGN_YOUTUBE_UPDATE_CLICK: 'RONIN_CAMPAIGN_YOUTUBE_UPDATE_CLICK',
  CAMPAIGN_YOUTUBE_SAVE_CLICK: 'RONIN_CAMPAIGN_YOUTUBE_SAVE_CLICK',
  CAMPAIGN_YOUTUBE_SUBMIT_CLICK: 'RONIN_CAMPAIGN_YOUTUBE_SUBMIT_CLICK',
  CAMPAIGN_DOCUMENTS_TAB_CLICK: 'RONIN_CAMPAIGN_DOCUMENTS_TAB_CLICK',
  CAMPAIGN_RISKS_TAB_CLICK: 'RONIN_CAMPAIGN_RISKS_TAB_CLICK',
  CAMPAIGN_RISKS_SAVE_CLICK: 'RONIN_CAMPAIGN_RISKS_SAVE_CLICK',
  CAMPAIGN_QA_TAB_CLICK: 'RONIN_CAMPAIGN_QA_TAB_CLICK',
  CAMPAIGN_QA_SAVE_CLICK: 'RONIN_CAMPAIGN_QA_SAVE_CLICK',
  CAMPAIGN_CROWDFUNDING_UPDATE_CLICK: 'RONIN_CAMPAIGN_CROWDFUNDING_UPDATE_CLICK',
  CAMPAIGN_CROWDFUNDING_SUBMIT_CLICK: 'RONIN_CAMPAIGN_CROWDFUNDING_SUBMIT_CLICK',
  INVESTMENT_NEWS_TAB_CLICK: 'RONIN_INVESTMENT_NEWS_TAB_CLICK',
  INVESTMENT_DOCUMENTS_TAB_CLICK: 'RONIN_INVESTMENT_DOCUMENTS_TAB_CLICK',
  INVESTMENT_WIZARD_BACK_CLICK: 'RONIN_INVESTMENT_WIZARD_BACK_CLICK',
  INVESTMENT_WIZARD_NEXT_CLICK: 'RONIN_INVESTMENT_WIZARD_NEXT_CLICK',
  INVESTMENT_WIZARD_CONFIRM_CLICK: 'RONIN_INVESTMENT_WIZARD_CONFIRM_CLICK',
  INVESTMENT_WIZARD_GET_VERIFIED_CLICK: 'RONIN_INVESTMENT_WIZARD_GET_VERIFIED_CLICK',
  INVESTMENT_WIZARD_RETRY_VERIFICATION_CLICK: 'RONIN_INVESTMENT_WIZARD_RETRY_VERIFICATION_CLICK',
  INVESTMENT_WIZARD_CONTACT_SUPPORT_CLICK: 'RONIN_INVESTMENT_WIZARD_CONTACT_SUPPORT_CLICK',
  INVESTMENT_DESCRIPTION_TAB_CLICK: 'RONIN_INVESTMENT_DESCRIPTION_TAB_CLICK',
  INVESTMENT_DESCRIPTION_SAVE_CLICK: 'RONIN_INVESTMENT_DESCRIPTION_SAVE_CLICK',
  INVESTMENT_TEAM_TAB_CLICK: 'RONIN_INVESTMENT_TEAM_TAB_CLICK',
  INVESTMENT_TEAM_UPDATE_CLICK: 'RONIN_INVESTMENT_TEAM_UPDATE_CLICK',
  INVESTMENT_TEAM_ADD_CLICK: 'RONIN_INVESTMENT_TEAM_ADD_CLICK',
  INVESTMENT_TEAM_REMOVE_CLICK: 'RONIN_INVESTMENT_TEAM_REMOVE_CLICK',
  INVESTMENT_YOUTUBE_TAB_CLICK: 'RONIN_INVESTMENT_YOUTUBE_TAB_CLICK',
  INVESTMENT_YOUTUBE_UPDATE_CLICK: 'RONIN_INVESTMENT_YOUTUBE_UPDATE_CLICK',
  INVESTMENT_YOUTUBE_SUBMIT_CLICK: 'RONIN_INVESTMENT_YOUTUBE_SUBMIT_CLICK',
  INVESTMENT_RISKS_TAB_CLICK: 'RONIN_INVESTMENT_RISKS_TAB_CLICK',
  INVESTMENT_RISKS_SAVE_CLICK: 'RONIN_INVESTMENT_RISKS_SAVE_CLICK',
  INVESTMENT_QA_TAB_CLICK: 'RONIN_INVESTMENT_QA_TAB_CLICK',
  INVESTMENT_QA_SAVE_CLICK: 'RONIN_INVESTMENT_QA_SAVE_CLICK',
  INVESTMENT_ONGOING_TAB_SAVE_CLICK: 'RONIN_INVESTMENT_ONGOING_TAB_SAVE_CLICK',
  INVESTMENT_UPCOMING_TAB_CLICK: 'RONIN_INVESTMENT_UPCOMING_TAB_CLICK',
  INVESTMENT_ENDED_TAB_SAVE_CLICK: 'RONIN_INVESTMENT_ENDED_TAB_SAVE_CLICK',
  NEWS_FEED_CREATE_CLICK: 'RONIN_NEWS_FEED_CREATE_CLICK',
  NEWS_FEED_UPDATE_STATUS_CLICK: 'RONIN_NEWS_FEED_UPDATE_STATUS_CLICK',
  NEWS_FEED_UPDATE_MESSAGE_CLICK: 'RONIN_NEWS_FEED_UPDATE_MESSAGE_CLICK',
  NEWS_FEED_UPLOAD_DOCUMENT_CLICK: 'RONIN_NEWS_FEED_UPLOAD_DOCUMENT_CLICK',
  NEWS_FEED_DOWNLOAD_DOCUMENT_CLICK: 'RONIN_NEWS_FEED_DOWNLOAD_DOCUMENT_CLICK',
  NEWS_FEED_DISCARD_DOCUMENT_CLICK: 'RONIN_NEWS_FEED_DISCARD_DOCUMENT_CLICK',

  CAMPAIGN_DETAILS_GET_PRIORITY_BUTTON_CLICK: 'RONIN_CAMPAIGN_DETAILS_GET_PRIORITY_BUTTON_CLICK',
  INVESTMENT_WIZARD_STEP_TICKET_SELECT: 'RONIN_INVESTMENT_WIZARD_STEP_TICKET_SELECT',
  INVESTMENT_WIZARD_STEP_KYC_PROCESS_NEXT: 'RONIN_INVESTMENT_WIZARD_STEP_KYC_PROCESS_NEXT',
  INVESTMENT_WIZARD_INVESTMENT_FINISH: 'RONIN_INVESTMENT_WIZARD_INVESTMENT_FINISH',

  ISSUER_FILL_IN_PROFILE: 'RONIN_ISSUER_FILL_IN_PROFILE',
  FINISH_REGISTER_CLICK: 'RONIN_FINISH_REGISTER_CLICK',
  USER_CONFIRMED_EMAIL: 'RONIN_USER_CONFIRMED_EMAIL',

  RETRY_KYC_CLICK: 'RONIN_RETRY_KYC_CLICK',
  REDO_KYC_CLICK: 'RONIN_REDO_KYC_CLICK',

  ADD_EDUCATION_MATERIAL_CLICK: 'RONIN_ADD_EDUCATION_MATERIAL_CLICK',
};
