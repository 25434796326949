import { useAuth } from 'hooks';
import useIsValidEducationUser from 'hooks/useIsValidEducationUser';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { withErrorSuspense } from 'utils/withErrorSuspense';

interface InvestorInvestmentEducationGuardProps {
  children: ReactNode;
}

const InvestorInvestmentEducationGuard: FC<InvestorInvestmentEducationGuardProps> = (props) => {
  const { children } = props;
  const { user } = useAuth();
  // const isValidEducationUser = useIsValidEducationUser();

  const isValidEducationUser = ['ADMIN', 'ACCOUNT_MANAGER'].includes(user.role) || user.education;
  if (isValidEducationUser) {
    return <>{children}</>;
  }
  return <Navigate to="/dashboard" />;
};

export default withErrorSuspense(InvestorInvestmentEducationGuard);
