import { OpenAPIClientAxios, Document } from 'openapi-client-axios';
import { apiUrlJava } from 'utils/getApiUrlByEnv';
import KeycloakUserService from 'utils/KeycloakUserService';
import { Client as EdexJavaApi } from './EdexClientJava';
import swaggerJavaDefinition from './swagger-java.json';

const typedDefinitionJava = swaggerJavaDefinition as Document;

class AxiosClientJava {
  private readonly api: OpenAPIClientAxios;
  private client: EdexJavaApi;
  constructor() {
    // @ts-ignore
    this.api = new OpenAPIClientAxios({
      definition: typedDefinitionJava,
      withServer: {
        url: apiUrlJava,
      },
    });
  }
  getClient() {
    return this.client;
  }

  async setClient() {
    this.client = await this.api.getClient<EdexJavaApi>();
    this.client.interceptors.request.use((request) => {
      const session = KeycloakUserService.getToken();
      if (session) {
        request.headers['Authorization'] = `Bearer ${session}`;
      }
      return request;
    });
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const client = this.client;
          await KeycloakUserService.updateTokenForAxios();
          return client(originalRequest);
        } else if (error.response.status === 401 && originalRequest._retry) {
          window.dispatchEvent(new Event('LOGOUT_EVENT'));
        }
        if (error.response.status === 423) {
          window.dispatchEvent(new Event('COMPLETE_REGISTRATION_EVENT'));
        }
        return Promise.reject(error);
      }
    );
  }
}

const EdexClientJava = new AxiosClientJava();
export { EdexClientJava };
