import { ApiUrl, ApiUrlJava } from '../constants';

export const PRE_DEPLOYMENT_STAGING_TO_PRODUCTION_OVERWRITE = false;

const getApiUrlByEnv = (): ApiUrl | ApiUrlJava => {
  let { REACT_APP_ENV } = process.env;
  if (PRE_DEPLOYMENT_STAGING_TO_PRODUCTION_OVERWRITE && REACT_APP_ENV === 'staging') {
    REACT_APP_ENV = 'production';
  }
  switch (REACT_APP_ENV) {
    case 'local':
      return ApiUrlJava.local;
    case 'development':
      return ApiUrlJava.development;
    case 'staging':
      return ApiUrlJava.staging;
    case 'production':
      return ApiUrlJava.production;
    default:
      return undefined;
  }
};

const getKeycloakUrlByEnv = (): string => {
  let { REACT_APP_ENV } = process.env;
  if (PRE_DEPLOYMENT_STAGING_TO_PRODUCTION_OVERWRITE && REACT_APP_ENV === 'staging') {
    REACT_APP_ENV = 'production';
  }
  // return '/keycloak/local-keycloak.json';
  switch (REACT_APP_ENV) {
    case 'local':
      return '/keycloak/local-keycloak.json';
    case 'development':
      return '/keycloak/development-keycloak.json';
    case 'staging':
      return '/keycloak/staging-keycloak.json';
    case 'production':
      return '/keycloak/production-keycloak.json';
    default:
      return undefined;
  }
};

export const apiUrlJava = getApiUrlByEnv();
export const keycloakConfigUrl = getKeycloakUrlByEnv();
