import { Form, FormInput, FormInputType } from 'components/generic/Form';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { educationMaterialDescription, educationMaterialTitle } from 'utils/yup-validations';

export interface CreateEducationFormProps {
  title?: string;
  description?: string;
  position?: number;
  onChange: (values: any) => void;
  onValidityChange: (isValid: boolean) => void;
}
export type EducationCategoryFormInputs = {
  title: string;
  description: string;
  submit: any;
};

export const CreateEducationForm: FC<CreateEducationFormProps> = (
  props: CreateEducationFormProps
): JSX.Element => {
  const { title, description, position, onChange, onValidityChange } = props;
  const { t } = useTranslation();

  const inputs: FormInput[] = [
    {
      name: 'title',
      label: t('education.createMaterial.title'),
      validation: educationMaterialTitle,
      type: FormInputType.TEXT_FIELD,
      disabled: false,
    },
    {
      name: 'description',
      label: t('education.createMaterial.description'),
      validation: educationMaterialDescription,
      type: FormInputType.TEXT_FIELD,
      disabled: false,
    },
    {
      name: 'position',
      label: t('education.createMaterial.position'),
      validation: '',
      type: FormInputType.NUMBER,
      disabled: false,
    },
  ];

  const initialValues = { title, description, position, submit: null };

  return (
    <Form<EducationCategoryFormInputs>
      inputs={inputs}
      showSubmitBtn={false}
      initialValues={initialValues}
      onValuesChange={onChange}
      onValidityChange={onValidityChange}
      enableReinitialize
    />
  );
};
