import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { getLanguage } from 'utils';

const useCurrentLanguage = (): [string, Function] => {
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage());

  useEffect(() => {
    i18next.on('languageChanged', (lng) => {
      setCurrentLanguage(lng);
    });
  }, []);
  return [currentLanguage, setCurrentLanguage];
};

export default useCurrentLanguage;
