import { EdexClientJava } from '../lib/axios';
import type { CompanyMember } from 'types/company';
import { EducationEpisode } from 'types/education';

export const addEducationEpisode = async ({
  id,
  title,
  position,
  videoFile,
  supportMaterial,
}: {
  id: number;
  title: string;
  position: number;
  videoFile: Blob;
  supportMaterial: Blob;
}): Promise<EducationEpisode> => {
  try {
    const client = await EdexClientJava.getClient();
    const formDataPayload = new FormData();
    if (videoFile) {
      formDataPayload.append('videoFile', videoFile);
    }
    if (supportMaterial) {
      formDataPayload.append('supportMaterial', supportMaterial);
    }

    const response = await client.educationControllerAddEducationEpisode(
      {
        id,
        title,
        position,
      },
      formDataPayload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    const member = response.data as EducationEpisode;
    return member;
  } catch (err) {
    throw new Error(err);
  }
};
